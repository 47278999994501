import { Injectable } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { CreateSite, GetSitesResp, GitRepoResp, GitreposService, SiteResp, SitesService, SortOrder, Status, UpdateSite } from '@ecs/ecs-api';
import { AbstractPaginatableListService } from '@ecs/ecs-common';
import { AlertType, KeswickAlertService, OrgService } from '@ecs/ecs-platform';
import { L10nService } from '@vmw/ngx-vip';
import { Observable, finalize, map, of, switchMap, take, tap, catchError, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigureSitesService extends AbstractPaginatableListService<SiteResp> {
  private defaultSortColumn = 'updated_at';
  siteList = new BehaviorSubject<number>(0);

  constructor(
    private orgService: OrgService,
    private sitesService: SitesService,
    private gitRepoService: GitreposService,
    private keswickAlertService: KeswickAlertService,
    private l10nService: L10nService
  ) {
    super();
  }

  fetchData(pagination: ClrDatagridStateInterface<SiteResp>): Observable<void> {
    this.loadingSource.next(true);

    const { current, size } = pagination.page;
    return this.orgService.orgId$.pipe(switchMap(orgId => {
      const sortColumn = pagination.sort?.by || this.defaultSortColumn;
      const sortOrder: SortOrder = pagination.sort?.reverse ? SortOrder.Desc : SortOrder.Asc;

      const filters: Record<string, string> = pagination.filters?.reduce((acc, filter) => ({
        ...acc,
        [filter.property]: filter.value
      }), {});
      return this.sitesService.listSites(orgId, current, size, sortColumn as any, sortOrder, filters);
    }), map(sites => {
      this.paginationSource.next({
        ...pagination,
        page: {
          ...pagination.page,
          current: sites.page,
          size: sites.per_page,
          from: sites.per_page * (sites.page - 1) + 1,
          to: sites.per_page * sites.page
        },
        filters: pagination.filters ?? [],
      });
      this.dataSource.next(sites.results);
      this.totalItemsSource.next(sites.total);
    }), tap(() => {
      this.loadingSource.next(false);
    }),
      finalize(() => {
        this.loadingSource.next(false);
      }));
  }

  getSitesCount(orgID: string) {
    this.sitesService.listSites(orgID).subscribe((site) => {
      this.siteList.next(site.results ? site.results.length : 0);
    });
  }

  loadGitRepos(organizationId: string): Observable<GitRepoResp[]> {
    return this.gitRepoService.listGitRepos(organizationId).pipe(
      map((repos) => {
        return repos.results;
      }));
  }

  createSite(siteData: CreateSite): Observable<SiteResp> {
    return this.orgService.orgId$.pipe(take(1), switchMap(orgId => {
      return this.sitesService.createSite(orgId, siteData).pipe(tap((site) => {
        this.keswickAlertService.showMessage({
          type: AlertType.success,
          message: this.l10nService.getMessage('ecs.sites.manageSite.addNew.success', [site.name]),
        });
      }), catchError((error) => {
        this.keswickAlertService.showMessage({
          type: AlertType.failure,
          message: this.l10nService.getMessage('ecs.sites.manageSite.addNew.failure', [siteData.name]),
        });
        return [error];
      }));
    })
    )
  }

  deleteSite(siteId: string): Observable<Status> {
    return this.orgService.orgId$.pipe(
      take(1),
      switchMap(orgId => {
        return this.sitesService.deleteSite(siteId, orgId)
      }))
  }

  getSite(siteId: string): Observable<SiteResp> {
    return this.orgService.orgId$.pipe(
      take(1),
      switchMap(orgId => {
        return this.sitesService.getSite(siteId, orgId)
      }))
  }

  updateSite(siteId: string, siteData: UpdateSite): Observable<SiteResp> {
    return this.orgService.orgId$.pipe(
      take(1),
      switchMap(orgId => {
        return this.sitesService.updateSite(siteId, orgId, siteData)
      }))
  }

  checkDuplicateSite(name:string):Observable<boolean>{
    return this.orgService.orgId$.pipe(
      take(1),
      switchMap(orgId => {
        return this.sitesService.checkDuplicateSite(orgId, name)
      }))
  }
}
