<clr-datagrid #gitReposDatagrid [(clrDgSelected)]="selectedGitRepos" [clrDgRowSelection]="true" [clrDgLoading]="loading">
  <clr-dg-action-bar>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary btn-link" (click)="openAddGitRepoModal()">
        <cds-icon shape="plus" class="refresh-icon"></cds-icon> {{ 'ecs.add' | vtranslate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary btn-link"
        [disabled]="selectedGitRepos?.length !== 1"
        (click)="openEditGitRepoModal()">
        <cds-icon shape="pencil" class="refresh-icon"></cds-icon> {{ 'ecs.edit' | vtranslate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary btn-link"
        [disabled]="selectedGitRepos?.length === 0"
        (click)="openRemoveGitRepoModal()">
        <cds-icon shape="trash" class="refresh-icon"></cds-icon> {{ 'ecs.delete' | vtranslate }}
      </button>
    </div>
  </clr-dg-action-bar>

  <clr-dg-column [clrDgField]="'id'" (clrFilterValueChange)="gridFilterChange($event)">
    <ng-container *clrDgHideableColumn="{ hidden: true }">{{ 'ecs.gitrepolist.column.repositoryid' | vtranslate }}</ng-container></clr-dg-column>
  <clr-dg-column [clrDgField]="'name'" (clrFilterValueChange)="gridFilterChange($event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.gitrepolist.column.repositoryname' | vtranslate }}</ng-container></clr-dg-column>
  <clr-dg-column [clrDgField]="'url'" (clrFilterValueChange)="gridFilterChange($event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.host.giturl' | vtranslate }}</ng-container></clr-dg-column>
  <clr-dg-column [clrDgField]="'default_branch'" (clrFilterValueChange)="gridFilterChange($event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.addgit.branch' | vtranslate }}</ng-container></clr-dg-column>
  <clr-dg-column [clrDgField]="'host_count'" (clrFilterValueChange)="gridFilterChange($event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.gitrepolist.column.hosts' | vtranslate }}</ng-container></clr-dg-column>
  <clr-dg-column [clrDgField]="'description'" (clrFilterValueChange)="gridFilterChange($event)">
    <ng-container *clrDgHideableColumn="{ hidden: false }">{{ 'ecs.addgit.description' | vtranslate }}</ng-container></clr-dg-column>

  <clr-dg-row *clrDgItems="let repo of gitrepos; let i = index" [clrDgItem]="repo">
    <clr-dg-cell>{{ repo.id }}</clr-dg-cell>
    <clr-dg-cell>{{ repo.name }}</clr-dg-cell>
    <clr-dg-cell>{{ repo.url }}</clr-dg-cell>
    <clr-dg-cell>{{ repo.default_branch }}</clr-dg-cell>
    <clr-dg-cell>
      <span *ngIf="!repo.host_count; else hostListLink">{{ repo.host_count }}</span>
      <ng-template #hostListLink>
        <a class="link" (click)="redirectToHostsPage(repo?.url)">{{ repo.host_count }}</a>
      </ng-template>
    </clr-dg-cell>
    <clr-dg-cell>{{ repo.description || '-' }}</clr-dg-cell>
  </clr-dg-row>

  <clr-dg-placeholder>
    <div class="empty-placeholder">
      <ecs-placeholder-no-data [placeholderCondition]="filtersApplied ? 1 : 0"
        [emptyStateMessage]="'ecs.gitrepo.noRepoMessageTitle' | vtranslate" [learnMoreLink]="REGISTER_GIT_REPO_DOC_LINK"
        [learnMoreText]="'ecs.gitrepo.noRepoMessageSubtitle' | vtranslate"
        [buttonName]="'ecs.gitrepo.addgitrepository' | vtranslate" (addButtonAction)="openAddGitRepoModal()">
      </ecs-placeholder-no-data>
    </div>
  </clr-dg-placeholder>

  <clr-dg-footer>
    <div class="footer-actions align-left">
      <a class="refresh-btn btn btn-link" (click)="refreshGitReposDatagrid()">
          <cds-icon shape="refresh" size="14"></cds-icon>
          <span> {{'ecs.refresh' | vtranslate}}</span>
      </a>
  </div>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ 'ecs.gitrepolist.footer' | vtranslate }}</clr-dg-page-size>
      {{ 'ecs.gitrepolist.pagination' | vtranslate : pagination.firstItem + 1 : pagination.lastItem + 1 : pagination.totalItems }}
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<ng-template #tmpl>
  <div *ngIf="!isSelectedRepoAssociatedWithHost()">
    <p>{{ 'ecs.gitrepolist.remove.confirmation' | vtranslate }}:</p>
    <ul>
      <li *ngFor="let repo of selectedGitRepos">
        <strong>{{ repo?.url }}</strong>
      </li>
      <br />
    </ul>
  </div>
  <div>{{ 'ecs.confirmtoproceed' | vtranslate }}</div>
  <div *ngIf="isSelectedRepoAssociatedWithHost()" class="alert alert-danger" role="alert">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
        </div>
        <span class="alert-text">
          {{ 'ecs.gitrepolist.remove.alert' | vtranslate }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
