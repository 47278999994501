<ng-container *ngIf="data$ | async  as data">
    <clr-datagrid [(clrDgSelected)]="selectedItems" [clrDgRowSelection]="true" [clrDgLoading]="loading$ | async"
        (clrDgRefresh)="onDatagridRefresh($event)">

        <!-- Action buttons -->
        <clr-dg-action-bar>
            <div class="btn-group">
                <button type="button" class="btn btn-sm btn-primary btn-link" (click)="addSite()">
                    <cds-icon shape="plus" class="refresh-icon"></cds-icon> {{ 'ecs.sites.datagrid.actions.addSite' | vtranslate }}
                </button>
                <button type="button" class="btn btn-sm btn-secondary btn-link" [disabled]="selectedItems?.length !== 1"
                    (click)="editSite(selectedItems[0])">
                    <cds-icon shape="pencil" class="refresh-icon"></cds-icon> {{ 'ecs.sites.datagrid.actions.editSite' | vtranslate }}
                </button>
                <button type="button" class="btn btn-sm btn-secondary btn-link" [disabled]="selectedItems?.length === 0 "
                    (click)="deleteSite()">
                    <cds-icon shape="trash" class="refresh-icon"></cds-icon> {{ 'ecs.sites.datagrid.actions.deleteSite' | vtranslate }}
                </button>
             </div>
        </clr-dg-action-bar>

        <!-- Columns -->
        <ng-container *ngFor="let column of columns">
        <clr-dg-column  *ngIf="column.field !== 'updated_at'" [clrDgField]="column.field" [clrDgSortBy]="column.field" [clrDgSortOrder]="SortOrder.DESC">
            <ng-container *clrDgHideableColumn="{ hidden: false }">{{ column.label  | vtranslate }}</ng-container>
        </clr-dg-column>
        <clr-dg-column  *ngIf="column.field === 'updated_at'" [clrDgField]="column.field" [clrDgSortBy]="column.field" [clrDgSortOrder]="SortOrder.DESC">
            <ng-container *clrDgHideableColumn="{ hidden: false }">{{ column.label | vtranslate }}</ng-container>
            <clr-dg-filter>
            <clr-date-container>
                <label>{{ 'ecs.host.startdate' | vtranslate }}:</label>
                <input
                  type="date"
                  autocomplete="off"
                  name="filterUpdatedStartDate"
                  clrDate
                  [ngModel]="updatedAtRangeStart$ | async | date: 'MM/dd/yyyy'"
                  (ngModelChange)="handleFilterDateRange($event, 'startDate')"/>
              </clr-date-container>
              <clr-date-container>
                <label>{{ 'ecs.host.enddate' | vtranslate }}:</label>
                <input
                  type="date"
                  autocomplete="off"
                  name="filterUpdatedEndDate"
                  clrDate
                  [ngModel]="updatedAtRangeEnd$ | async | date: 'MM/dd/yyyy'"
                  (ngModelChange)="handleFilterDateRange($event, 'endDate')"/>
              </clr-date-container>
            </clr-dg-filter>
        </clr-dg-column>
    </ng-container>

        <!-- Rows -->
        <clr-dg-row *ngFor="let site of data;" [clrDgItem]="site">
            <clr-dg-cell>{{ site.name }}</clr-dg-cell>
            <clr-dg-cell>{{ site.description }}</clr-dg-cell>
            <clr-dg-cell>{{ site.hosts_count }}</clr-dg-cell>
            <clr-dg-cell>
                <clr-signpost *ngIf="getLabels(site).length; else noTags;">
                    <a class="link-normal tags-link" href="javascript:void(0)" clrSignpostTrigger>{{getLabels(site).length}}</a>
                    <clr-signpost-content *clrIfOpen [clrPosition]="'right-middle'">
                        <div class="tags-container"  *ngFor="let label of getLabels(site)">
                            <label class="label label-light-blue">{{ label.label_key }}
                                <span class="badge">{{ label.label_value }}</span>
                            </label>
                        </div>
                    </clr-signpost-content>
                </clr-signpost>
                <ng-template #noTags>
                    <span>0</span>
                  </ng-template>
            </clr-dg-cell>
            <clr-dg-cell>{{ site.git_repo_name }}</clr-dg-cell>
            <clr-dg-cell>{{ site.updated_at | ecsDateTime }}</clr-dg-cell>
        </clr-dg-row>

        <!-- Details pane -->
        <clr-dg-detail *clrIfDetail="let detail">
            <clr-dg-detail-header>{{ detail.name }}</clr-dg-detail-header>
            <clr-dg-detail-body>
              <ecs-site-details [site]="detail"></ecs-site-details>
            </clr-dg-detail-body>
          </clr-dg-detail>

        <!-- Empty Placeholder -->
        <clr-dg-placeholder>
            <div class="empty-placeholder">
                    <ecs-placeholder-no-data [placeholderCondition]="(pagination$ | async).filters?.length"
                        [emptyStateMessage]="'ecs.sites.datagrid.noSiteMessageTitle' | vtranslate"
                        [learnMoreLink]="ADD_SITE_DOC_LINK" [learnMoreText]="'ecs.sites.datagrid.noSiteMessageSubtitle' | vtranslate"
                        [buttonName]="'ecs.sites.datagrid.actions.addSite' | vtranslate" (addButtonAction)="addSite()">
                    </ecs-placeholder-no-data>
            </div>
        </clr-dg-placeholder>

        <!-- Footer -->
        <clr-dg-footer *ngIf="pagination$ | async as paginationInfo">
            <div class="footer-actions align-left">
                <a class="refresh-btn btn btn-link" (click)="onRefresh()">
                    <cds-icon shape="refresh" size="14"></cds-icon>
                    <span>{{'ecs.refresh' | vtranslate}}</span>
                </a>
            </div>
            <clr-dg-pagination
              #pagination
              [clrDgPageSize]="paginationInfo?.page?.size"
              [clrDgPage]="paginationInfo?.page?.current"
              [clrDgTotalItems]="totalItems$ | async">
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"> {{'ecs.sites.datagrid.pagination.pageSize' | vtranslate}} </clr-dg-page-size>
              {{'ecs.sites.datagrid.pagination.countDetails' | vtranslate:pagination.firstItem + 1:pagination.lastItem + 1: (totalItems$ | async)}}
                </clr-dg-pagination>
                <div></div>
          </clr-dg-footer>
    </clr-datagrid>
</ng-container>
