<div *ngIf="placeholderCondition > 0">
    <img class="empty-no-data-image" src="/apps/ecs/assets/grid-no-data-light.svg" alt="No entries" />
    <p class="custom-para">{{ 'ecs.datagrid.nodata' | vtranslate }}</p>
    <p> {{'ecs.datagrid.nodata.message' | vtranslate }}</p>
</div>

<div *ngIf="placeholderCondition === 0">
    <img class="empty-no-data-image" src="/apps/ecs/assets/empty-state-light.svg" alt="No entries" />
    <p class="custom-para">{{ emptyStateMessage }}</p>
    <p><a *ngIf="learnMoreText" class="link" [href]="learnMoreLink" target="_blank">{{ learnMoreText }}</a></p>
    <button *ngIf="buttonName" type="button" class="btn btn-primary empty-state-add-btn" (click)="addButton()">
        {{ buttonName }}
    </button>
</div>