<div class="card">
    <div class="card-block">
        <div class="clr-row">
            <div class="clr-col-sm-12 clr-col-md-6 ecs-charts">
                <div class="clr-col-sm-12 clr-col-md-4 ecs-charts-center">
                    <div class="ecs-disply">
                        <h4>{{'ecs.host.health.status.total' | vtranslate}}</h4>
                        <div>{{totalHost}}</div>
                    </div>
                </div>
                <div class="clr-col-sm-12 clr-col-md-4" *ngIf="chartDataByRegistrationStatus">
                    <vmw-donut class="container-class"
                        [data]="chartDataByRegistrationStatus"
                        [title]="totalHost"
                        [subtitle]="''"
                        [enablePatterns]="false" 
                        [donutArcSize]=30 
                        [disableValueLabels]="true">
                    </vmw-donut>
                </div>
                <div class="clr-col-sm-12 clr-col-md-4  ecs-display-box">
                    <div>
                        <h4>{{'ecs.host.registraion.status.title' | vtranslate}}</h4>
                    </div>
                    <!-- <div *ngFor="let status of chartDataByRegistrationStatus">
                        <div [ngClass]="'box ' + status.color"></div> {{status.name +': '+ status.value}}
                    </div> -->
                    <table>
                        <tr *ngFor="let status of chartDataByRegistrationStatus">
                            <td><div [ngClass]="'box ' + status.id"></div> {{status.name}}:</td>
                            <td>{{status.value}}</td></tr>
                        
                    </table>

                </div>

            </div>
            <div class="clr-col-sm-12 clr-col-md-6 ecs-charts">
                <div class="clr-col-sm-12 clr-col-md-4">
                    <vmw-donut 
                        class="container-class" 
                        [data]="chartDataByHealthStatus" 
                        [title]="totalHealtyUnHealthy"
                        [subtitle]="''" 
                        [enablePatterns]="false" 
                        [donutArcSize]=30 
                        [disableValueLabels]="true">
                    </vmw-donut>
                </div>
                <div class="clr-col-sm-12 clr-col-md-4  ecs-display-box">
                    <div>
                        <h4>{{'ecs.host.health.health.status.title' | vtranslate}}</h4>
                    </div>
                    <!-- <div *ngFor="let status of chartDataByHealthStatus">
                        <div [ngClass]="'box ' + status.color"></div> {{status.name +': '+ status.value}}
                    </div> -->
                    <table>
                        <tr *ngFor="let status of chartDataByHealthStatus">
                            <td><div [ngClass]="'box ' + status.id"></div> {{status.name}}:</td>
                            <td>{{status.value}}</td></tr>
                    </table>
                </div>
                <div class="clr-col-sm-12 clr-col-md-4 ecs-charts-center">
                    <div class="ecs-disply">
                        <h4>{{'ecs.host.health.status.activated.host' | vtranslate}}</h4>
                        <div>{{totalActivated}}</div>
                    </div>
                </div>
            </div>

        </div>

    </div>


    <!-- <div class="close-banner" (click)="hideHeroBanner()">
      <cds-icon shape="window-close" size="md"></cds-icon>
    </div> -->
</div>