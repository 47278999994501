import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, ReplaySubject, BehaviorSubject, finalize, tap, switchMap, map } from 'rxjs';
import { HostDetailsResp, HostsService as HostsApiService, HostResp, SortOrder, HostRegistrationStatus, HostsCountGroupBy, HostOperationalStatus   } from '@ecs/ecs-api';

import {
  ESortOrder,
  IHostData,
  PaginatedHostsResponse,
  OrgService,
  HOST_DEFAULT_FILTERS
} from '@ecs/ecs-platform';

import { AbstractPaginatableListService } from '@ecs/ecs-common';
import { ClrDatagridStateInterface } from '@clr/angular';
import { Params } from '@angular/router';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MonitorHostsService extends AbstractPaginatableListService<HostResp>{
  private defaultSortColumn = 'updated_at';
  private readonly hostDetailsSource = new BehaviorSubject<HostDetailsResp | null>(null);
  private selectedSource = new BehaviorSubject<Array<HostResp>>([]);


  readonly dataSource$ = this.dataSource.asObservable();
  readonly selected$ = this.selectedSource.asObservable();

  private activeHostsPresent = false;
  orgID: string;
  // addToGroupModalRef: VmwNgxModalRef<OkCancel>;
  allHostsList = new Subject<PaginatedHostsResponse>();
  activeHostList = new ReplaySubject<PaginatedHostsResponse>(1);
  inActiveHostList = new ReplaySubject<PaginatedHostsResponse>(1);

  hostDetails$ = this.hostDetailsSource.asObservable();

  // Filters for host datagrid
  public hostNameInput: string;
  public serialNumberFilterInput: string;
  public modelFilterInput: string;
  public vendorFilterInput: string;
  public usernameFilterInput: string;
  public groupLabelFilterInput: string;
  public gitConfigSourceInput: string;
  public hostHealthStatus: HostRegistrationStatus[];
  public hostOperationalStatus: HostOperationalStatus[];
  // Filter for host_health
  public hostStatusPending: string;
  public hostStatusActive: string;
  public urlFilterInput: string;
  // Filter for last_seen
  public filterStartDate: string;
  public filterEndDate: string;
  public readonly EXPECTED_FILTER_CHANGE_ENTRIES: number = 2;
  public defaultSelectValue = HOST_DEFAULT_FILTERS;
  public filterInputChanged = new EventEmitter<[string, string | string[]]>();
  public lastSeenStartDateChanged = new EventEmitter();
  public lastSeenEndDateChanged = new EventEmitter();
  hostHealthArr: HostRegistrationStatus[];
  operationalStatusArr: HostOperationalStatus[];
  public filterValues: Map<string, string> = new Map<string, string>();

  constructor(private http: HttpClient, private orgService: OrgService, private hostsApi: HostsApiService) {
    super();
    this.orgService.orgId$.subscribe((orgID) => {
      this.orgID = orgID;
    });
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  fetchData(pagination: ClrDatagridStateInterface<HostResp>): Observable<void> {
    this.loadingSource.next(true);

    const { current, size } = pagination.page;
    return this.orgService.orgId$.pipe(switchMap(orgId => {
      // const sortColumn = pagination.sort?.by || this.defaultSortColumn;
      const sortOrder: SortOrder = pagination.sort?.reverse ? SortOrder.Desc : SortOrder.Asc;

      return this.hostsApi.listHosts(orgId, current, size, null, sortOrder, undefined, this.hostHealthArr, this.operationalStatusArr, Object.fromEntries(this.filterValues));
    }), map(hosts => {
      this.paginationSource.next({
        ...pagination,
        page: {
          ...pagination.page,
          current: hosts.page,
          size: hosts.per_page,
          from: hosts.per_page * (hosts.page - 1) + 1,
          to: hosts.per_page * hosts.page
        }
      });
      this.dataSource.next(hosts.results);
      this.totalItemsSource.next(hosts.total);
    }), tap(() => {
      this.loadingSource.next(false);
    }),
    finalize(() => {
      this.loadingSource.next(false);
    }));
  }

  getHosts(
    hostStatus?: Array<HostRegistrationStatus>,
    operationalStatus?: Array<HostOperationalStatus>,
    page = 1,
    perPage = 20,
    sortColumn = 'id',
    sortOrder = ESortOrder.ASCENDING,
    filterValues = new Map<string, string>()
  ) {
    hostStatus = hostStatus && hostStatus.length > 0 ? hostStatus : undefined;
    operationalStatus = operationalStatus && operationalStatus.length > 0 ? operationalStatus : undefined;
    const apiRequest = this.hostsApi.listHosts(
      this.orgID,
      page,
      perPage,
      sortColumn as any,
      sortOrder,
      undefined,
      hostStatus,
      operationalStatus,
      Object.fromEntries(filterValues)
    );

      apiRequest.subscribe((hostsResp) => {
        this.dataSource.next(hostsResp.results);
        this.totalItemsSource.next(hostsResp.total);
      }), tap(() => {
          this.loadingSource.next(false);
        }),
        finalize(() => {
          this.loadingSource.next(false);
        });
  }

  getHostsCount(
    groupBy?: HostsCountGroupBy,
    filterValues = new Map<string, string>()
  ) {
    groupBy = groupBy  ? groupBy : undefined;
    return this.hostsApi.getHostsCount(
      this.orgID,      
      groupBy,
      Object.fromEntries(filterValues)
    );
  }

  setSelected(selection: Array<any>): void {
    this.selectedSource.next(selection);
  }

  setQueryParams(params: Params, hostHealthArr: HostRegistrationStatus[], operationalStatusArr: HostOperationalStatus[]) {
    if (!_.isEmpty(params)) {
       Object.keys(params).forEach((key: string) => {
        this.onFilterValueChange(key, params[key as keyof typeof params], hostHealthArr, operationalStatusArr)
        })
    } else {
      this.resetFilterValues();
    }
}

onFilterValueChange(filterColumn: string, filterValue: string, hostHealthArr?: HostRegistrationStatus[], operationalStatusArr?: HostOperationalStatus[]) {
  switch (filterColumn) {    
    case 'name': {
      this.hostNameInput = filterValue;
      break;
    }
    case 'host_serial_number': {
      this.serialNumberFilterInput = filterValue;
      break;
    }
    case 'host_model_identifier': {
      this.modelFilterInput = filterValue;
      break;
    }
    case 'host_registration_status': {
      this.hostHealthStatus = hostHealthArr;
      break;
    }
    case 'host_operational_status': {
      this.hostOperationalStatus = operationalStatusArr;
      break;
    }
    case 'host_vendor_identifier': {
      this.vendorFilterInput = filterValue;
      break;
    }
    case 'git_config_source': {
      this.gitConfigSourceInput = filterValue;
      break;
    }
    case 'url': {
      this.urlFilterInput = filterValue;
      break;
    }
    case 'created_by': {
      this.usernameFilterInput = filterValue;
      break;
    }
    case 'desired_group_label': {
      this.groupLabelFilterInput = filterValue;
      break;
    }
    case 'last_seen_range_start': {
      this.filterStartDate = filterValue;
      break;
    }
    case 'last_seen_range_end': {
      this.filterEndDate = filterValue;
      break;
    }
    default: {
      console.log('Unsupported filterColumn received');
    }
  }
}

resetFilterValues() {
  this.hostNameInput = '';
  this.serialNumberFilterInput = '';
  this.modelFilterInput = '';
  this.vendorFilterInput = '';
  this.hostStatusActive = '';
  this.hostStatusPending = '';
  this.gitConfigSourceInput = '';
  this.urlFilterInput = '';
  this.filterStartDate = this.filterEndDate = '';
  this.usernameFilterInput = '';
  this.groupLabelFilterInput = '';
}



}
