<h2>{{ 'ecs.operations.alerts.title' | vtranslate }}</h2>
<ng-container *ngIf="data$ | async  as data">
  <clr-datagrid [(clrDgSelected)]="selectedItems" [clrDgLoading]="loading$ | async" (clrDgRefresh)="onDatagridRefresh($event)">
    <clr-dg-action-bar>
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-sm btn-secondary btn-link"
          [disabled]="selectedItems.length === 0"
          (click)="openDismissAlertModal()">
          <cds-icon shape="times" class="refresh-icon"></cds-icon> {{'ecs.dismissalert' | vtranslate}}
        </button>
      </div>
    </clr-dg-action-bar>
    <clr-dg-column [clrDgField]="'message'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
      {{'ecs.operations.alerts.columns.alert' | vtranslate}}</ng-container>
      <!-- <clr-dg-filter>
        <input
          type="text"
          class="clr-input ng-pristine ng-valid ng-touched"
          placeholder="{{'ecs.operations.alerts.columns.alert.placeholder' | vtranslate}}"
          [(ngModel)]="alertMessageFilterInput"
          (input)="onFilterChange('message', $event)" />
      </clr-dg-filter> -->
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'severity'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">
      {{'ecs.operations.alerts.columns.severity' | vtranslate}}</ng-container>
      <clr-dg-filter>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox value="Warning" [(ngModel)]="warningAlert" (change)="onFilterChange('severity', $event)" />
          <label><cds-icon shape="exclamation-triangle" solid status="warning"></cds-icon>{{'ecs.operations.alerts.columns.severity.warning' | vtranslate}}</label>
        </clr-checkbox-wrapper>
        <clr-checkbox-wrapper>
          <input type="checkbox" clrCheckbox value="Critical" [(ngModel)]="criticalAlert" (change)="onFilterChange('severity', $event)" />
          <label><cds-icon shape="info-standard" size="sm" solid status="danger"></cds-icon>{{'ecs.operations.alerts.columns.severity.critical' | vtranslate}}</label>
        </clr-checkbox-wrapper>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column>
      <ng-container *clrDgHideableColumn="{ hidden: false }">
      {{'ecs.operation.source' | vtranslate}}</ng-container>
      <!-- <clr-dg-filter>
        <input
          type="text"
          class="clr-input ng-pristine ng-valid ng-touched"
          placeholder="{{'ecs.operations.alerts.columns.source.placeholder' | vtranslate}}"
          [(ngModel)]="alertSourceFilterInput"
          (input)="onFilterChange('host_serial_number', $event)" />
      </clr-dg-filter> -->
    </clr-dg-column>
    <clr-dg-column>
      <ng-container *clrDgHideableColumn="{ hidden: false }">
      {{'ecs.host.grouplabel' | vtranslate}}</ng-container>
      <!-- <clr-dg-filter>
        <input
          type="text"
          class="clr-input ng-pristine ng-valid ng-touched"
          placeholder="{{'ecs.operations.alerts.columns.grouplabel.placeholder' | vtranslate}}"
          (input)="onFilterChange('group_label', $event)" />
      </clr-dg-filter> -->
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'updated_at'">
      <ng-container *clrDgHideableColumn="{ hidden: false }">{{'ecs.operation.time' | vtranslate}}</ng-container></clr-dg-column>
    
    <clr-dg-row *ngFor="let alert of data" [clrDgItem]="alert?.details?.id">
      <clr-dg-cell>{{ alert?.details?.message }}</clr-dg-cell>
      <clr-dg-cell>
        <cds-icon *ngIf="alert?.details?.severity === severity.WARNING" shape="warning-standard" status="warning" solid>
        </cds-icon>
        <cds-icon *ngIf="alert?.details?.severity === severity.CRITICAL" shape="info-standard" size="sm" solid
          status="danger"></cds-icon>
        {{ alert?.details?.severity }}
      </clr-dg-cell>
      <clr-dg-cell class="source-link">
        <a [routerLink]="[partialLinkToConfigureHostDetailsView, alert.host_uid]">{{ alert?.name }}</a>
      </clr-dg-cell>
      <clr-dg-cell>
        <div>
          <ng-container *ngIf="alert?.group_labels?.length === 0">-</ng-container>
          <ng-container *ngFor="let label of alert?.group_labels?.slice(0, 2)">
            <label class="label label-light-blue">
              {{ label | ecsLabelParser : 'key' }}
              <span class="badge">{{ label | ecsLabelParser : 'value' }}</span>
            </label>
          </ng-container>
          <ng-container *ngIf="alert?.group_labels?.length > 2"> {{ alert?.group_labels?.length - 2 }} more...
          </ng-container>
        </div>
      </clr-dg-cell>
      <clr-dg-cell>{{ alert?.details?.updated_at | ecsDateTime }}</clr-dg-cell>
    </clr-dg-row>

    <!-- Empty Placeholder -->
    <clr-dg-placeholder>
      <div class="empty-placeholder">
        <ecs-placeholder-no-data [placeholderCondition]="(pagination$ | async).filters?.length"
          [emptyStateMessage]="'ecs.operations.noalerts' | vtranslate">
        </ecs-placeholder-no-data>
      </div>
    </clr-dg-placeholder>

    <!-- Footer -->
    <clr-dg-footer *ngIf="pagination$ | async as paginationInfo">
      <div class="footer-actions align-left">
        <a class="refresh-btn btn btn-link" (click)="onRefresh()">
          <cds-icon shape="refresh" size="14"></cds-icon>
          <span>{{'ecs.refresh' | vtranslate}}</span>
        </a>
      </div>
      <clr-dg-pagination #pagination [clrDgPageSize]="paginationInfo?.page?.size"
      [clrDgPage]="paginationInfo?.page?.current" [clrDgTotalItems]="totalItems$ | async" (clrDgPageChange)="onPageChange()">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]" (change)="onPageChange()"> {{'ecs.operations.alerts.footer' | vtranslate}}
      </clr-dg-page-size>
      {{'ecs.operations.alerts.pagination' | vtranslate:pagination.firstItem + 1:pagination.lastItem + 1: (totalItems$ |
      async)}}
    </clr-dg-pagination>
      <div></div>
    </clr-dg-footer>

  </clr-datagrid>
</ng-container>

<ng-template #tmpl>
  <div>{{'ecs.operations.alerts.dismissalertconfirmation' | vtranslate}}</div>
</ng-template>