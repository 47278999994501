import { ENG_ECS_ADD_HOST_MODAL_TRANSLATIONS } from './../components/add-host-modal-content/add-host-modal-content.l10n';
import { ENG_ECS_ADD_TO_GROUP_MODAL_TRANSLATIONS } from './../components/add-to-group-modal/add-to-group-modal.l10n';
import { ENG_ECS_EDIT_HOST_MODAL_TRANSLATIONS } from './../components/edit-host-modal/edit-host-modal.l10n';
import { ENG_ECS_GETTING_STARTED_TRANSLATIONS } from './../components/getting-started/getting-started.l10n';
import { ENG_ECS_GROUPS_TRANSLATIONS } from './../components/group/groups/groups.l10n';
import { ENG_ECS_GROUPS_LIST_TRANSLATIONS } from './../components/group/groups-list/groups-list.l10n';
import { ENG_ECS_GROUPS_REMOVE_TRANSLATIONS } from './../components/group/groups-remove/groups-remove.l10n';
import { ENG_ECS_GROUPS_UPDATE_TRANSLATIONS } from './../components/group/groups-update/groups-update.l10n';
import { ENG_ECS_HOST_HARDWARE_FILTERS_TRANSLATIONS } from './../components/hosts/filters/filters.l10n';
import { ENG_ECS_HOST_DETAILS_TRANSLATIONS } from './../components/host-details/host-details.l10n';
import { ENG_ECS_HOST_HARDWARE_TRANSLATIONS } from './../components/host-details/host-hardware-detail/host-hardware-detail.l10n';
import { ENG_ECS_REMOVE_HOST_MODAL_TRANSLATIONS } from './../components/remove-host-modal-content/remove-host-modal-content.l10n';
import { ENG_ECS_LAUNCHPAD_FOOTER_TRANSLATIONS } from './../components/launchpad-footer/launchpad-footer.l10n';
import { ENG_ECS_LAUNCHPAD_TRANSLATIONS } from './../components/launchpad/launchpad.l10n';
import { ENG_ECS_HOSTS_TRANSLATIONS } from '@ecs/ecs-platform';
import { ENG_ECS_GITREPO_TRANSLATIONS } from './../git-repo/gitrepo/gitrepo.l10n';
import { ENG_ECS_ADD_GITREPO_TRANSLATIONS } from './../git-repo/add-gitrepo-modal/add-gitrepo-modal.l10n';
import { ENG_ECS_GITREPO_LIST_TRANSLATIONS } from './../git-repo/gitrepos-list/gitrepos-list.l10n';
import { ENG_ECS_APP_TRANSLATIONS } from './../app.l10n';
import { ENG_ECS_ACTIVATE_HOST_MODAL_TRANSLATIONS } from './../components/activate-host-modal/activate-host-modal.l10n';
import { ENG_ECS_SERVICE_TRANSLATIONS } from './../services/service.l10n';
import { ENG_ECS_COMMON_TRANSLATIONS } from './configuration/ecs-common-translations.l10n';
import { ENG_ECS_REBOOT_HOST_MODAL_TRANSLATIONS } from './../components/reboot-host-modal-content/reboot-host-modal-content.l10n';
import { ENG_ECS_CONFIGURE_SITES_TRANSLATIONS } from '@ecs/ecs-configure-sites';
import { PatternCategories } from '@vmw/ngx-vip';
import { ENG_ECS_ADD_HOST_TRANSLATIONS } from '../components/add-host/add-host.l10n';
import { ENG_ECS_ADD_HOST_SITE_DETAILS_TRANSLATIONS } from '../components/add-host-site-details/add-host-site-details.l10n';
import { ENG_ECS_ALERTS_TRANSLATIONS, ENG_ECS_EVENTS_TRANSLATIONS } from '@ecs/ecs-monitor';

export const EcsI18nConfig = {
  productID: 'ECS',
  component: 'VecoEcsUI',
  version: '3.8.0',
  i18nScope: [PatternCategories.DATE, PatternCategories.NUMBER, PatternCategories.CURRENCIES],
  translationBundles: {},
  isPseudo: false,
  sourceBundles: [
    ENG_ECS_SERVICE_TRANSLATIONS,
    ENG_ECS_COMMON_TRANSLATIONS,
    ENG_ECS_ACTIVATE_HOST_MODAL_TRANSLATIONS,
    ENG_ECS_ADD_HOST_MODAL_TRANSLATIONS,
    ENG_ECS_ADD_TO_GROUP_MODAL_TRANSLATIONS,
    ENG_ECS_EDIT_HOST_MODAL_TRANSLATIONS,
    ENG_ECS_GETTING_STARTED_TRANSLATIONS,
    ENG_ECS_GROUPS_TRANSLATIONS,
    ENG_ECS_GROUPS_LIST_TRANSLATIONS,
    ENG_ECS_GROUPS_REMOVE_TRANSLATIONS,
    ENG_ECS_GROUPS_UPDATE_TRANSLATIONS,
    ENG_ECS_HOST_HARDWARE_FILTERS_TRANSLATIONS,
    ENG_ECS_HOST_HARDWARE_TRANSLATIONS,
    ENG_ECS_HOST_DETAILS_TRANSLATIONS,
    ENG_ECS_REMOVE_HOST_MODAL_TRANSLATIONS,
    ENG_ECS_EVENTS_TRANSLATIONS,
    ENG_ECS_ALERTS_TRANSLATIONS,
    ENG_ECS_LAUNCHPAD_FOOTER_TRANSLATIONS,
    ENG_ECS_LAUNCHPAD_TRANSLATIONS,
    ENG_ECS_HOSTS_TRANSLATIONS,
    ENG_ECS_GITREPO_TRANSLATIONS,
    ENG_ECS_ADD_GITREPO_TRANSLATIONS,
    ENG_ECS_GITREPO_LIST_TRANSLATIONS,
    ENG_ECS_APP_TRANSLATIONS,
    ENG_ECS_REBOOT_HOST_MODAL_TRANSLATIONS,
    ENG_ECS_CONFIGURE_SITES_TRANSLATIONS,
    ENG_ECS_ADD_HOST_TRANSLATIONS,
    ENG_ECS_ADD_HOST_SITE_DETAILS_TRANSLATIONS
  ],
  timeout: 5000
};
