<div class="clr-row">
  <clr-timeline [clrLayout]="ClrTimelineLayout.VERTICAL">
    <clr-timeline-step [clrState]="getAddGitRepoStatus()">
      <clr-timeline-step-title>{{ 'ecs.launchpad.gettingtarted.gitrepository.title' | vtranslate }}</clr-timeline-step-title>
      <clr-timeline-step-description class="description">
        <div>
          <a class="link" (click)="openAddGitRepoModal()" target="_blank" rel="noopener noreferrer">
            {{ 'ecs.launchpad.gettingtarted.gitrepository.add' | vtranslate }}
          </a>
          <span [innerHTML]="'ecs.launchpad.gettingtarted.gitrepository' | vtranslate"></span>
          <a [href]="LEARN_MORE_ABOUT_GIT_REPO" target="_blank" rel="noopener noreferrer">
            {{ 'ecs.launchpad.gettingtarted.gitrepository.description' | vtranslate }}
          </a>
        </div>
      </clr-timeline-step-description>
    </clr-timeline-step>
    <clr-timeline-step [clrState]="downloadStatus$ | async">
      <clr-timeline-step-title>{{ 'ecs.launchpad.gettingtarted.download.title' | vtranslate }} </clr-timeline-step-title>
      <clr-timeline-step-description class="description">
        <div>
          {{ 'ecs.launchpad.gettingtarted.download.description' | vtranslate }}&nbsp;
          <a class="link" [href]="DOWNLOAD_IMAGE_DOC_LINK" target="_blank" rel="noopener noreferrer">
            {{ 'ecs.learnmore' | vtranslate }}
          </a>
        </div>
        <clr-dropdown class="download-button">
          <button class="btn btn-primary" clrDropdownTrigger>
            {{ 'ecs.download' | vtranslate }}
            <cds-icon shape="angle" direction="down"></cds-icon>
          </button>
          <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
            <div clrDropdownItem *ngFor="let imageType of imageTypes" (click)="downloadImage(imageType)">{{ imageType.toUpperCase() }}</div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </clr-timeline-step-description>
    </clr-timeline-step>
    <clr-timeline-step [clrState]="getAddSiteStatus()">
      <clr-timeline-step-title>{{ 'ecs.launchpad.gettingtarted.sitemanagment.title' | vtranslate }}</clr-timeline-step-title>
      <clr-timeline-step-description class="description">
        <div>
          <a class="link" (click)="openAddSiteModal()" target="_blank" rel="noopener noreferrer">
            {{ 'ecs.launchpad.gettingtarted.sitemanagment.description.add' | vtranslate }}
          </a>
          <span
            [innerHTML]="'ecs.launchpad.gettingtarted.sitemanagment.description' | vtranslate">
          </span>
          <span>
          <a class="link" [href]="LEARN_MORE_ABOUT_SITES" target="_blank" rel="noopener noreferrer">
            {{ 'ecs.learnmore' | vtranslate }}
          </a>
        </span>
        </div>
      </clr-timeline-step-description>
    </clr-timeline-step>
    <clr-timeline-step [clrState]="getAddHostStatus()">
      <clr-timeline-step-title>{{ 'ecs.launchpad.gettingtarted.hostmanagment.title' | vtranslate }}</clr-timeline-step-title>
      <clr-timeline-step-description class="description">
        <div>
          <a class="link" (click)="openAddHostModal()" target="_blank" rel="noopener noreferrer">
            {{ 'ecs.launchpad.gettingtarted.hostmanagment.description.add' | vtranslate }}
          </a>
          <span
            [innerHTML]="'ecs.launchpad.gettingtarted.hostmanagment.description' | vtranslate">
          </span>
          <a class="link" [href]="HOST_PRE_REQ_DOC_LINK" target="_blank" rel="noopener noreferrer">
            {{ 'ecs.launchpad.gettingtarted.hostmanagment.subdescription' | vtranslate }}
          </a>
        </div>
      </clr-timeline-step-description>
    </clr-timeline-step>
    <clr-timeline-step [clrState]="ClrTimelineStepState.CURRENT">
      <clr-timeline-step-title>{{ 'ecs.launchpad.gettingtarted.exploregroups.title' | vtranslate }}</clr-timeline-step-title>
      <clr-timeline-step-description class="description">
        {{ 'ecs.launchpad.gettingtarted.exploregroups.description' | vtranslate }}
      </clr-timeline-step-description>
    </clr-timeline-step>
  </clr-timeline>
</div>
