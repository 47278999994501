import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GetLogBundleResp, HostDetailsResp, HostRegistrationStatus, LogsService, HostRegDetails } from '@ecs/ecs-api';
import { Lumos, convertLabelsToUILabels, getTimeStamp } from '@ecs/ecs-common';
import { AlertType, ICapacityAndUsageData, IHostData, KeswickAlertService, MONITOR_ALERTS_ROUTE, MONITOR_EVENTS_ROUTE, OrgService, getAdjustedRoute } from '@ecs/ecs-platform';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { L10nService } from '@vmw/ngx-vip';
import { map, switchMap, take, withLatestFrom } from 'rxjs';
import { AddHostToGroupModalService } from '../../services/add-host-to-group-modal.service';
import { HostsService } from '../../services/hosts.service';
import { RemoveHostModalService } from '../../services/remove-host-modal.service';
import { RebootHostService } from './../../services/reboot-host.service';

@Component({
  selector: 'app-host-details',
  templateUrl: './host-details.component.html',
  styleUrls: ['./host-details.component.scss']
})
export class HostDetailsComponent extends Unsubscriber implements OnInit {
  readonly deleting$ = this.removeHostModalService.deleting$;
  readonly hostData$ = this.hostService.hostDetails$;

  private hosts: IHostData[] = [];
  private hostId: string;

  readonly HostRegistrationStatus = HostRegistrationStatus;

  public loading = true;
  public capacityAndUsageData: ICapacityAndUsageData;
  public getTimeStamp = getTimeStamp;

  private readonly WARNING_PERCENTAGE = 90;

  constructor(
    public router: Router,
    private readonly hostService: HostsService,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly removeHostModalService: RemoveHostModalService,
    private readonly addHostToGroupService: AddHostToGroupModalService,
    private readonly alertService: KeswickAlertService,
    private readonly orgService: OrgService,
    private readonly l10service: L10nService,
    private readonly rebootHostService: RebootHostService,
    private readonly logsService: LogsService,
  ) {
    super();
  }

  ngOnInit() {
    this.hostId = this.route.snapshot.paramMap.get('hostId');
    this.hostService.getSelectedHosts();
    this.hostService.fetchHostDetails(this.hostId);
    this.subs = this.hostData$.subscribe((hostDetails: HostDetailsResp) => {
      this.loading = false;
      this.setCapacityAndUsageData(hostDetails);
    });
  }

  alertsClicked() {
    Lumos.trackClickEvent('Host Details', 'Host Details ACTIONS clicked');
  }

  redirectToAlerts() {
    Lumos.trackClickEvent('Host Details', 'Host Details ACTIONS - Alerts clicked');
    this.subs = this.hostData$.subscribe((hostData: HostDetailsResp) => {
      this.router.navigate([getAdjustedRoute(this.router, MONITOR_ALERTS_ROUTE)], {
        queryParams: { host_unique_identifier: hostData?.host_summary?.name}
      });
    });
  }

  redirectToEvents() {
    Lumos.trackClickEvent('Host Details', 'Host Details ACTIONS - Events clicked');
    this.subs = this.hostData$.subscribe((hostData: HostDetailsResp) => {
      const queryParamValue = (hostData?.host_summary?.host_registration_details?.host_registration_type ===
        HostRegDetails.HostRegistrationTypeEnum.ActivationKey) ? hostData?.host_summary?.host_registration_details?.host_activation_key
        : hostData?.host_summary?.host_registration_details?.host_serial_number;
      this.router.navigate([getAdjustedRoute(this.router, MONITOR_EVENTS_ROUTE)], {
        queryParams: { queryParamValue }
      });
    });
  }

  backClicked() {
    this.location.back();
  }

  getThresholdValue(capacity: number) {
    const value = (capacity * this.WARNING_PERCENTAGE) / 100;
    return value;
  }

  getUtilizationPercentage(capacity: number, used: number) {
    return (used / capacity) * 100;
  }

  openRemoveHostConfirmationModal() {
    Lumos.trackClickEvent('Host Details', 'Host Details ACTIONS - Remove clicked');
    this.subs = this.hostData$
      .pipe(
        take(1),
        switchMap((hostData: HostDetailsResp) => {
          const hostIdentifier = hostData.host_summary.unique_identifier;
          const hostSerialNumber = hostData?.host_summary?.host_registration_details?.host_serial_number;
          const hostRegistrationStatus = hostData?.host_summary?.host_registration_status;
          const hostName = hostData?.host_summary?.name


          return this.removeHostModalService.openRemoveHostModal([{ hostIdentifier, hostSerialNumber, hostRegistrationStatus, hostName }], true);
        })
      )
      .subscribe();
  }

  get unappliedGroups$() {
    return this.hostData$.pipe(
      map((hostData: HostDetailsResp) => {
        const appliedGroups: string[] = hostData?.host_summary?.applied_group_labels;
        const desiredGroups: string[] = hostData?.host_summary?.desired_group_labels.concat(convertLabelsToUILabels(hostData?.host_summary?.autoGeneratedLabels));
        const unappliedGroups: string[] = desiredGroups.filter((item) => !appliedGroups.includes(item));
        return unappliedGroups;
      })
    );
  }


  refreshHostDetails() {
    this.loading = true;
    this.hostService.fetchHostDetails(this.hostId);
  }

  openAddToGroupModal() {
    Lumos.trackClickEvent('Host Details', 'Host Details ACTIONS - Add to group clicked');
    this.addHostToGroupService.openAddHostToGroupModal([{ unique_identifier: this.hostId }] as IHostData[], true);
  }

  generateLogBundle() {
    Lumos.trackClickEvent('Host Details', 'Host Details - GENERATE LOG BUNDLE clicked');
    this.subs = this.hostData$
      .pipe(
        take(1),
        switchMap((hostData: HostDetailsResp) => {
          return this.hostService.generateLogBundle(hostData?.host_summary?.unique_identifier);
        })
      )
      .subscribe(() => {
        this.alertService.showMessage({
          type: AlertType.success,
          message: this.l10service.getMessage('ecs.hostdetail.generatelogbundlesuccess')
        });
      });
  }

  downloadLogBundle() {
    this.subs = this.hostData$
      .pipe(take(1), withLatestFrom(this.orgService.orgId$))
      .pipe(switchMap(([hostData, id]) => {
        return this.logsService.getLogBundle(hostData?.host_summary?.unique_identifier, id);
      })).subscribe((response: GetLogBundleResp) => {
        const url = response.log_bundle_url;
        const fileName = url.split('#')[0].split('?')[0].split('/').pop();
        let downloadLink = document.getElementById('lnkLogBundleDownload') as HTMLAnchorElement;
        if (downloadLink == null) {
         downloadLink = document.createElement('a');
         downloadLink.id = 'lnkLogBundleDownload';
        }
        downloadLink.download = fileName;
        downloadLink.href = url;
        downloadLink.click();
      });
  }

  splitKubernetesDetail(kubernetesVersion: string) {
    /**
     * the format of kubernetesVersion string is "Control Plane Version : v1.27.2+vmware.1-tiny.1\nWorker Node Version : v1.27.2"
     * First we need to slit the string with "\n" then again split with ":"
     */
    const versions = kubernetesVersion.split('\n');
    return [...versions[0].split(':'), ...versions[1].split(':')];
  }

  setCapacityAndUsageData(hostData: HostDetailsResp) {
    const cpuCapacity = hostData?.host_summary?.host_cpu_capacity;
    const cpuUtilization = hostData?.host_system_details?.cpu_utilization;
    const memoryCapacity = hostData?.host_summary?.host_memory_capacity;
    const memoryUtilization = hostData?.host_system_details?.memory_utilization;
    const storageCapacity = hostData?.host_summary?.host_storage_capacity;
    const storageUtilization = hostData?.host_system_details?.storage_utilization;

    this.capacityAndUsageData = {
      data: [
        {
          usage: cpuUtilization,
          capacity: cpuCapacity,
          threshold: this.getThresholdValue(cpuCapacity),
          unit: this.l10service.getMessage('ecs.hostdetail.capacitydata.utilization.unit'),
          label: this.l10service.getMessage('ecs.hostdetail.capacitydata.utilization.label'),
          showWarning: this.getUtilizationPercentage(cpuCapacity, cpuUtilization) > this.WARNING_PERCENTAGE,
          warningText: this.l10service.getMessage('ecs.hostdetail.capacitydata.utilization.warningText', [this.WARNING_PERCENTAGE])
        },
        {
          usage: memoryUtilization,
          capacity: memoryCapacity,
          threshold: this.getThresholdValue(memoryCapacity),
          unit: this.l10service.getMessage('ecs.hostdetail.capacitydata.memory.unit'),
          label: this.l10service.getMessage('ecs.hostdetail.capacitydata.memory.label'),
          showWarning: this.getUtilizationPercentage(memoryCapacity, memoryUtilization) > this.WARNING_PERCENTAGE,
          warningText: this.l10service.getMessage('ecs.hostdetail.capacitydata.memory.warningText', [this.WARNING_PERCENTAGE])
        },
        {
          usage: storageUtilization,
          capacity: storageCapacity,
          threshold: this.getThresholdValue(storageCapacity),
          unit: this.l10service.getMessage('ecs.hostdetail.capacitydata.storage.unit'),
          label: this.l10service.getMessage('ecs.hostdetail.capacitydata.storage.label'),
          showWarning: this.getUtilizationPercentage(storageCapacity, storageUtilization) > this.WARNING_PERCENTAGE,
          warningText: this.l10service.getMessage('ecs.hostdetail.capacitydata.storage.warningText', [this.WARNING_PERCENTAGE])
        }
      ]
    };
  }

  formatGitStatus(status: string): string {
    if (status) {
      // Convert Git status to readable format by inserting spacing between words.
      // For example, "PullInProgress" status received from the backend will be
      // displayed as "Pull In Progress"
      return status.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
    return status;
  }

  openRebootHostConfirmationModal(hostData: HostDetailsResp) {
    Lumos.trackClickEvent('Host Details', 'Host Details ACTIONS - Reboot clicked');
    this.subs = this.rebootHostService.openRebootHostModal(hostData.host_summary).subscribe();
  }
}
