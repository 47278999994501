import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes, CommonAppRoutes, CommonRouteParams, EnterpriseRoutes, NavigationState } from '@ecs/ecs-platform';
import { errorsRoutes } from './errors/errors.routes';
// import { RequireLoggedInGuard } from '@vmw/ngx-csp-oauth2';
import { AddHostComponent } from './components/add-host/add-host.component';
import { GroupsComponent } from './components/group/groups/groups.component';
import { HostDetailsComponent } from './components/host-details/host-details.component';
import { HostsComponent } from './components/hosts/hosts.component';
import { LaunchpadComponent } from './components/launchpad/launchpad.component';
import { GitrepoComponent } from './git-repo/gitrepo/gitrepo.component';
import { GitreposListComponent } from './git-repo/gitrepos-list/gitrepos-list.component';
import { UnsavedChangesGuard } from '@ecs/ecs-common';

const routes: Routes = [
  { path: '', redirectTo: AppRoutes.configure, pathMatch: 'full' },
  { path: EnterpriseRoutes.launchpad, redirectTo: AppRoutes.configure, pathMatch: 'full' },
  {
    path: AppRoutes.monitor,
    // canActivate: [RequireLoggedInGuard],
    loadChildren: () => import('./lazy-libs/lazy-ecs-monitor.module').then(m => m.LazyEcsMonitorModule),
    data: {
      navigationState: NavigationState.ENTERPRISE_MONITOR
    }
  },
  {
    path: AppRoutes.configure,
    // canActivate: [RequireLoggedInGuard],
    children: [
      { path: '', redirectTo: EnterpriseRoutes.launchpad, pathMatch: 'full' },
      {
        path: EnterpriseRoutes.launchpad,
        component: LaunchpadComponent,
        data: {
          navigationState: NavigationState.ENTERPRISE_CONFIGURE
        }
      },
      {
        path: EnterpriseRoutes.gitrepos,
        component: GitrepoComponent,
        children: [
          { path: '', redirectTo: EnterpriseRoutes.gitreposlist, pathMatch: 'full' },
          {
            path: EnterpriseRoutes.gitreposlist,
            component: GitreposListComponent,
            data: {
              navigationState: NavigationState.ENTERPRISE_CONFIGURE
            }
          }
        ]
      },
      {
        path: EnterpriseRoutes.groups,
        component: GroupsComponent,
        data: {
          navigationState: NavigationState.ENTERPRISE_CONFIGURE
        }
      },
      {
        path: EnterpriseRoutes.inventory,
        children: [
          { path: '', redirectTo: EnterpriseRoutes.hosts, pathMatch: 'full' },
          {
            path: EnterpriseRoutes.hosts,
            component: HostsComponent,
            data: {
              navigationState: NavigationState.ENTERPRISE_CONFIGURE
            }
          },
          {
            path: `${EnterpriseRoutes.hostDetails}/:hostId`,
            component: HostDetailsComponent,
            data: {
              navigationState: NavigationState.ENTERPRISE_CONFIGURE
            }
          },
          {
            path: EnterpriseRoutes.addHost,
            component: AddHostComponent,
            canDeactivate: [UnsavedChangesGuard]
          },
          {
            path: `${EnterpriseRoutes.hosts}/:id`,
            component: AddHostComponent,
          },
        ]
      },
      {
        path: EnterpriseRoutes.sites,
        // canActivate: [RequireLoggedInGuard],
        loadChildren: () => import('./lazy-libs/lazy-configure-sites.module').then(m => m.LazyConfigureSitesModule),
      },
    ],
    data: {
      navigationState: NavigationState.ENTERPRISE_CONFIGURE
    }
  },
  ...errorsRoutes
];

const enterpriseContextRoutes = {
  path: `${CommonAppRoutes.customers}/:${CommonRouteParams.customerId}/${CommonAppRoutes.ecs}`,
  children: routes
}

const operatorUserRoutes = {
  path: CommonAppRoutes.operator,
  // canActivate: [AuthGuard],
  children: [
    enterpriseContextRoutes,
    {
      path: `${CommonAppRoutes.msp}/:${CommonRouteParams.mspId}`,
      // canActivate: [MspContextGuard],
      children: [
        enterpriseContextRoutes
      ]
    }
  ]
};

const partnerUserRoutes = {
  path: CommonAppRoutes.msp,
  // canActivate: [AuthGuard],
  children: [
    enterpriseContextRoutes
  ]
};

const enterpriseUserRoutes = {
  path: CommonAppRoutes.ecs,
  children: routes
};

const appRoutes: Routes = [
  operatorUserRoutes,
  partnerUserRoutes,
  enterpriseUserRoutes
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
