<h2>{{ 'ecs.operations.events.title' | vtranslate }}</h2>
<p class="groups-para">{{'ecs.operations.events' | vtranslate}}</p>
<ng-container *ngIf="data$ | async  as data">
  <clr-datagrid [clrDgRowSelection]="false" [clrDgLoading]="loading$ | async"
    (clrDgRefresh)="onDatagridRefresh($event)">
    <clr-dg-action-bar>

    </clr-dg-action-bar>
    <!-- Columns -->
    <clr-dg-column *ngFor="let column of columns" [clrDgSortBy]="(column.field === 'created_at') ? column.field : null"
      [clrDgSortOrder]="SortOrder.DESC">
      <ng-container *clrDgHideableColumn="{ hidden: false }">{{ column.label | vtranslate }}</ng-container>
    </clr-dg-column>

    <!-- Rows -->
    <clr-dg-row *ngFor="let eventItem of data">
      <clr-dg-cell>{{ eventItem?.description }}</clr-dg-cell>
      <clr-dg-cell *ngIf="isUUID(eventItem?.source)">
        <a [routerLink]="[partialLinkToConfigureHostDetailsView, eventItem?.source]">{{ eventItem?.source }}</a>
      </clr-dg-cell>
      <clr-dg-cell *ngIf="!isUUID(eventItem?.source)">{{ eventItem?.sourceName }}</clr-dg-cell>
      <clr-dg-cell>{{ eventItem?.created_at | date : 'medium' }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-placeholder>
      <div class="empty-placeholder">
        <img class="empty-no-data-image" src="/apps/ecs/assets/empty-state-light.svg" alt="No entries" />
        <p class="custom-para">{{ 'ecs.events.datagrid.pagination.noevents' | vtranslate }}</p>
      </div>
    </clr-dg-placeholder>

    <!-- Footer -->
    <clr-dg-footer *ngIf="pagination$ | async as paginationInfo">
      <div class="footer-actions align-left">
        <a class="refresh-btn btn btn-link" (click)="onRefresh()">
          <cds-icon shape="refresh" size="14"></cds-icon>
          <span>{{'ecs.refresh' | vtranslate}}</span>
        </a>
      </div>
      <clr-dg-pagination #pagination [clrDgPageSize]="paginationInfo?.page?.size"
        [clrDgPage]="paginationInfo?.page?.current" [clrDgTotalItems]="totalItems$ | async">
        <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"> {{'ecs.operations.events.footer' | vtranslate}}
        </clr-dg-page-size>
        {{'ecs.events.datagrid.pagination.countDetails' | vtranslate:pagination.firstItem + 1:pagination.lastItem + 1:
        (totalItems$ |
        async)}}
      </clr-dg-pagination>
       
    </clr-dg-footer>

  </clr-datagrid>
</ng-container>