<div>
    <div>
        <div *ngIf="!isExpanded">
        <span>{{ bannerDescription }}</span>
        <button class="btn-expand-collapse" (click)="handleExpandCollapse(!isExpanded)">{{ 'ecs.more' | vtranslate }}</button>
       </div>
       <div *ngIf="isExpanded" class="expanded-mode">
        <button class="btn-expand-collapse show-less" (click)="handleExpandCollapse(!isExpanded)">{{ 'ecs.less' | vtranslate }}</button>
        <div class="content" #myContentNode>
            <ng-content></ng-content>
        </div>
    </div>


    </div>
</div>