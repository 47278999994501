export const ENG_ECS_GITREPO_TRANSLATIONS = {
  'ecs.gitrepo.banner':
    'All Git repositories will be listed here. Git repositories are used to store configurations that will be synced to your organization.',
  'ecs.gitrepo.gettingstarted': 'Getting Started with Git',
  'ecs.gitrepo.description': 'To use this feature, first add a Git repository.',
  'ecs.gitrepo.addgitrepository': 'ADD A GIT REPOSITORY',
  'ecs.gitrepo.learnmore': 'LEARN MORE',
  'ecs.gitrepo.subdescription':
    `<a class="link" target="_blank" rel="noopener noreferrer"> View instructions</a> on how to use ` +
    `common Git repositories. ` + 
    `All Git repositories will be listed here. Git repositories are used to store configurations that will be synced to your organization.` +
    `This will allow you to provide a repository and sync the desired state and all future updates for your Edge hosts. `
};
