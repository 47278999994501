import { Injectable } from '@angular/core';
import { AlertsService as AlertsApi, SortOrder,  AlertResp } from '@ecs/ecs-api';
import { OrgService } from '@ecs/ecs-platform';
import { finalize, map, Observable, switchMap, tap } from 'rxjs';
import { AbstractPaginatableListService } from '@ecs/ecs-common';
import { ClrDatagridStateInterface } from '@clr/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertsService extends AbstractPaginatableListService<AlertResp> {
  private readonly url = '/api/ecs/v0/orgs';
  private orgID: string;
  private defaultSortColumn = 'updated_at';

  constructor( private orgService: OrgService, private alertsApi: AlertsApi) {
    super(); 
  }

  
  fetchData(pagination: ClrDatagridStateInterface<AlertResp>): Observable<void> {
    this.loadingSource.next(true);

    const { current, size } = pagination.page;
    return this.orgService.orgId$.pipe(switchMap(orgId => {
      const sortColumn = pagination.sort?.by || this.defaultSortColumn;
      const sortOrder: SortOrder = pagination.sort?.reverse ? SortOrder.Desc : SortOrder.Asc;

      let filters: Record<string, string> = pagination.filters?.reduce((acc, filter) => ({
        ...acc,
        [filter.property]: filter.value
      }), {});
      filters = {...filters}
      return this.alertsApi.getAlerts(orgId, current, size, sortColumn as any, sortOrder, filters);
    }), map(alerts => {
      this.paginationSource.next({
        ...pagination,
        page: {
          ...pagination.page,
          current: alerts.page,
          size: alerts.per_page,
          from: alerts.per_page * (alerts.page - 1) + 1,
          to: alerts.per_page * alerts.page
        },
        filters: pagination.filters ?? [],
      });
      this.dataSource.next(alerts.results??[]);
      this.totalItemsSource.next(alerts.total);
    }), tap(() => {
      this.loadingSource.next(false);
    }),
      finalize(() => {
        this.loadingSource.next(false);
      }));
  }
 
}
