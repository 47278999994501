import { ChangeDetectorRef, Component } from '@angular/core';
import { GroupService } from './group.service';
import { GroupResp } from '@ecs/ecs-api';
import { RemoveGroupModalService } from '../../../services/remove-group-modal.service';
import { UpdateGroupModalService } from '../../../services/update-group-modal.service';
import { Router } from '@angular/router';
import { ADD_GROUP_DOC_LINK, HOST_GROUP_DOC_LINK, AbstractPaginatableListComponent, Lumos } from '@ecs/ecs-common';
import { CONFIGURE_HOSTS_ROUTE, MONITOR_ALERTS_ROUTE, ESortOrder, getAdjustedRoute } from '@ecs/ecs-platform'; 
import { GROUP_LIST_COLUMNS } from './group-list-config'; 
import { ClrDatagridSortOrder } from '@clr/angular';

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss']
})
export class GroupsListComponent  extends AbstractPaginatableListComponent<GroupResp> { 
  readonly columns = GROUP_LIST_COLUMNS;
  readonly SortOrder = ClrDatagridSortOrder;
  readonly ADD_GROUP_DOC_LINK = ADD_GROUP_DOC_LINK;

  public selectedGroup: GroupResp;
  
  private sortOrder = ESortOrder.ASCENDING; // Default sortOrder
  private sortColumn = 'label'; // Default sortColumn  
  public loading = false;  
  public readonly HOST_GROUP_DOC_LINK = HOST_GROUP_DOC_LINK;

  constructor(
    private groupsService: GroupService,
    private removeGroupModalService: RemoveGroupModalService,
    private updateGroupModalService: UpdateGroupModalService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    super(groupsService);
  }

  openDeleteGroupModal() {
    this.subs = this.removeGroupModalService.openRemoveGroupModal(this.selectedItems) 
    .subscribe(() => {
      this.selectedItems = [];
      this.onRefresh();
    });
  }

  openUpdateGroupModal() {
    if (this.selectedItems?.length === 1) {
      this.updateGroupModalService.openUpdateGroupModal(
        this.selectedItems?.[0]?.id,
        {
          label_key : this.selectedItems?.[0]?.label_key,
          label_value : this.selectedItems?.[0]?.label_value,
        },
        this.selectedItems?.[0]?.description,
        this.selectedItems?.[0]?.member_details?.hosts
      ) .subscribe(() => {
        this.selectedItems = [];
        this.onRefresh();
      });
    }
  }

  redirectToHostsPage(label_key: string, label_value: string) {
    this.router.navigate([getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE)], { queryParams: { desired_group_label: label_key + ':' + label_value } });
  }

  redirectToAlertsPage(label_key: string, label_value: string) {
    //Lumos.trackClickEvent('Groups', 'Groups - Redirect to Alerts Page from Groups list Alert column');
    this.router.navigate([getAdjustedRoute(this.router, MONITOR_ALERTS_ROUTE)], { queryParams: { group_label: label_key + ':' + label_value } });
  }
 

}
