import { Component } from '@angular/core';
import { DEPLOYING_HOME_LAB_WITH_KESWICK_LINK, 
  DEPLOY_HOST_DOC_LINK, 
  FULL_DEPLOYMENT_VIDEO_LINK, 
  INTRODUCE_KESWICK_ARTICLE_LINK, 
  LEARN_MORE_ABOUT_KESWICK, 
  SIMPLIFYING_EDGE_DEPLOY_ARTICLE_LINK } from '@ecs/ecs-common';
import { OrgService } from '@ecs/ecs-platform';
import { VmwClarityTheme, VmwClarityThemeService } from '@vmw/ngx-utils';

@Component({
  selector: 'app-launchpad-footer',
  templateUrl: './launchpad-footer.component.html',
  styleUrls: ['./launchpad-footer.component.scss']
})
export class LaunchpadFooterComponent {
  public readonly LEARN_MORE_ABOUT_KESWICK = LEARN_MORE_ABOUT_KESWICK;
  public readonly DEPLOY_HOST_DOC_LINK = DEPLOY_HOST_DOC_LINK;
  public readonly FULL_DEPLOYMENT_VIDEO_LINK = FULL_DEPLOYMENT_VIDEO_LINK;
  public readonly INTRODUCE_KESWICK_ARTICLE_LINK = INTRODUCE_KESWICK_ARTICLE_LINK;
  public readonly SIMPLIFYING_EDGE_DEPLOY_ARTICLE_LINK = SIMPLIFYING_EDGE_DEPLOY_ARTICLE_LINK;
  public readonly DEPLOYING_HOME_LAB_WITH_KESWICK_LINK = DEPLOYING_HOME_LAB_WITH_KESWICK_LINK;
  public currentTheme: VmwClarityTheme = sessionStorage.getItem('currentTheme') as VmwClarityTheme;

  constructor(private orgService: OrgService, private themeService: VmwClarityThemeService) {
    this.themeService.themeChange.subscribe((theme) => {
      this.currentTheme = theme as VmwClarityTheme;
    })
  }

  downloadOSL(): void {
    this.orgService.orgId$.subscribe((id) => {
      window.location.href = `/api/ecs/v0/orgs/${id}/installer?type=osl`;
    });
  }
}
