import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ecs-placeholder-no-data',
  templateUrl: './placeholder-no-data.component.html',
  styleUrls: ['./placeholder-no-data.component.scss']
})
export class PlaceholderNoDataComponent {

  @Input() emptyStateMessage: string;
  @Input() placeholderCondition: number;
  @Input() learnMoreLink: string;
  @Input() learnMoreText: string;
  @Input() buttonName: string;
  @Output() addButtonAction = new EventEmitter<string>();


  addButton() {
    this.addButtonAction.emit();
  }
}
