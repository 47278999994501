import { Component, Input } from '@angular/core';
import { ClrDatagridFilterInterface } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { Subject } from 'rxjs';
import { MonitorHostsService } from '../monitor-hosts.service';
import { IHostData } from '@ecs/ecs-platform';
import { HostOperationalStatus, HostRegistrationStatus } from '@ecs/ecs-api';
import * as _ from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-monitor-host-filter',
  templateUrl: './monitor-filters.component.html'
})
export class MonitorHostFilterComponent implements ClrDatagridFilterInterface<IHostData> {
  private isHealthStatusCheckBoxActive = false;
  private isOperationalStatusCheckBoxActive = false;

  public readonly HostRegistrationStatus = HostRegistrationStatus;
  public readonly HostOperationalStatus = HostOperationalStatus;
  public readonly searchTermMinimalLength = 1;
  public readonly seachDebounceTime = 100;

  public changes: any = new Subject<any>();
  public input: string;
  public statusList = [HostRegistrationStatus.Registered, HostRegistrationStatus.NotActive, HostRegistrationStatus.Activating, HostRegistrationStatus.Active, HostRegistrationStatus.DeletionPending];
  public operationalStatusList = [HostOperationalStatus.Online, HostOperationalStatus.Reboot];
  public checkboxStates: { [status: string]: boolean } = {};
  public operationalCheckboxStatus: { [status: string]: boolean } = {};
  


  @Input() filterColumn = '';
  @Input() isSearching = false;

  constructor(private hostsService: MonitorHostsService, private l10service: L10nService) {
  }

  ngOnInit() {
    this.populateFilterValues();
  }
  
 populateFilterValues() {
    if (this.filterColumn === 'host_health_status' && this.hostsService.hostHealthStatus?.length > 0) {
        this.hostsService.hostHealthStatus.forEach((status) => {
           this.handleStatusCheckboxChange(status, true);
        })
      }
    if (this.filterColumn === 'host_operational_status' && this.hostsService.hostHealthStatus.length > 0) {
        this.hostsService.hostHealthStatus.forEach((status) => {
           this.handleOperationalStatusCheckboxChange(status, true);
        })
    }
    if (this.filterColumn === 'last_seen' && this.hostsService.filterStartDate) {
      this.handleLastSeenFilterChange(true, this.convertDateFormat(this.hostsService.filterStartDate), true);
    }
    if (this.filterColumn === 'last_seen' && this.hostsService.filterEndDate) {
      this.handleLastSeenFilterChange(false, this.convertDateFormat(this.hostsService.filterEndDate), true);
    }
}

  handleOperationalStatusCheckboxChange(status: string, isChecked: boolean) {
    this.operationalCheckboxStatus[status] = isChecked;
    const selectedStatusList = [...this.operationalStatusList].filter(status => this.operationalCheckboxStatus[status]);
    this.isOperationalStatusCheckBoxActive = selectedStatusList.length > 0;
    this.hostsService.filterInputChanged.emit([this.filterColumn, selectedStatusList]);
  }

  public isActive(): boolean {
    switch (this.filterColumn) {
      case 'name': {
        return !!this.hostsService.hostNameInput;
      }
      case 'host_serial_number': {
        return !!this.hostsService.serialNumberFilterInput;
      }
      case 'host_model_identifier': {
        return !!this.hostsService.modelFilterInput;
      }
      case 'host_vendor_identifier': {
        return !!this.hostsService.vendorFilterInput;
      }
      case 'host_health_status': {
        return this.isHealthStatusCheckBoxActive;
      }
      case 'host_operational_status': {
        return this.isOperationalStatusCheckBoxActive;
      }
      case 'git_config_source': {
        return !!this.hostsService.gitConfigSourceInput;
      }
      case 'url': {
        return !!this.hostsService.urlFilterInput;
      }
      case 'last_seen': {
        return !!this.hostsService.filterStartDate || !!this.hostsService.filterEndDate;
      }
      case 'created_by': {
        return !!this.hostsService.usernameFilterInput;
      }
      case 'desired_group_label': {
        return !!this.hostsService.groupLabelFilterInput;
      }
      default: {
        return !!this.input;
      }
    }
  }

  public accepts(item: IHostData): boolean  {
    return true;
  }

  handleStatusCheckboxChange(status: string, isChecked: boolean) {
    this.checkboxStates[status] = isChecked;
    const selectedStatusList = [...this.statusList].filter(status => this.checkboxStates[status]);
    this.isHealthStatusCheckBoxActive = selectedStatusList.length > 0;
    this.hostsService.filterInputChanged.emit([this.filterColumn, selectedStatusList]);
  }

  public getNgModelValue(filterMetadata = ''): string {
    switch (this.filterColumn) {
      case 'name': {
        return this.hostsService.hostNameInput;
      }
      case 'host_serial_number': {
        return this.hostsService.serialNumberFilterInput;
      }
      case 'host_model_identifier': {
        return this.hostsService.modelFilterInput;
      }
      case 'host_vendor_identifier': {
        return this.hostsService.vendorFilterInput;
      }
      case 'host_health_status': {
        if (filterMetadata === 'Active') {
          return this.hostsService.hostStatusActive;
        } else if (filterMetadata === 'Pending') {
          return this.hostsService.hostStatusPending;
        }
        break;
      }
      case 'git_config_source': {
        return this.hostsService.gitConfigSourceInput;
      }
      case 'url': {
        return this.hostsService.urlFilterInput;
      }
      case 'last_seen': {
        if (filterMetadata === 'LastSeenRangeStart') { 
          return this.convertDateFormat(this.hostsService.filterStartDate);
        } else if (filterMetadata === 'LastSeenRangeEnd') {
          return this.convertDateFormat(this.hostsService.filterEndDate);
        }
        break;
      }
      case 'created_by': {
        return this.hostsService.usernameFilterInput;
      }
      case 'desired_group_label': {
        return this.hostsService.groupLabelFilterInput;
      }
      default: {
        // Unknown filterColumn
        return '';
      }
    }

    return '';
  }

  public handleLastSeenFilterChange(isRangeStart: boolean, event: string, filterFromQueryParams?: boolean) {
    if (isRangeStart) {
      this.hostsService.filterStartDate = event;
      filterFromQueryParams ?? this.hostsService.lastSeenStartDateChanged.emit();
    } else {
      this.hostsService.filterEndDate = event;
      filterFromQueryParams ?? this.hostsService.lastSeenEndDateChanged.emit();
    }
  }

  public onFilterValueChange(filterValue: string) {
   
    this.hostsService.onFilterValueChange(this.filterColumn, filterValue);

    this.hostsService.filterInputChanged.emit([this.filterColumn, filterValue]);
  }

  public convertDateFormat(lastSeenDate: string) {
    return moment(lastSeenDate).utc().add(1, 'days').format('MM/DD/YYYY');
  }

  public getPlaceholderMessage() {
    switch (this.filterColumn) {
      case 'name': {
        return this.l10service.getMessage('ecs.host.hostname.placeholder');
      }
      case 'host_serial_number': {
        return this.l10service.getMessage('ecs.host.serialnumber.placeholder');
      }
      case 'host_model_identifier': {
        return this.l10service.getMessage('ecs.host.model.placeholder');
      }
      case 'host_vendor_identifier': {
        return this.l10service.getMessage('ecs.host.vendor.placeholder');
      }
      case 'url': {
        return this.l10service.getMessage('ecs.host.filter.gitrepo.placeholder');
      }
      case 'created_by': {
        return this.l10service.getMessage('ecs.host.username.placeholder');
      }
      case 'desired_group_label': {
        return this.l10service.getMessage('ecs.grouplist.column.grouplabel.placeholder');
      }
      default: {
        return this.l10service.getMessage('ecs.host.filter.column.placeholder');
      }
    }
  }
}
