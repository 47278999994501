import { Component, Input, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'ecs-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss']
})
export class PageInfoComponent {

  @Input() bannerDescription: string;

  @ViewChild('myContentNode') contentNode: ElementRef;
  isExpanded: boolean = false;
  constructor() {}

  handleExpandCollapse(value: boolean): void {
    this.isExpanded = value;
  }
}
