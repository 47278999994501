import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HostRegistrationStatus, HostResp, HostRegDetails, HostOperationalStatus } from '@ecs/ecs-api';
import { AbstractPaginatableListComponent, DEPLOY_HOST_DOC_LINK, LEARN_MORE_ABOUT_HOSTS_ACTIVATION_STATUS, Lumos, REGISTER_HOST_DOC_LINK, convertLabelsToUILabels, getTimeStamp } from '@ecs/ecs-common';
import { ActivateHostInfo, AlertType, CONFIGURE_ADD_HOST_ROUTE, CONFIGURE_HOSTS_DETAILS_ROUTE, CONFIGURE_HOSTS_ROUTE, ESortOrder, HOST_DEFAULT_FILTERS, IHostData, KeswickAlertService, MONITOR_HOSTS_DETAILS_ROUTE, NavigationService, getAdjustedRoute } from '@ecs/ecs-platform';
import { VmwNgxModalService, VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import * as _ from 'lodash';
import { Observable, catchError, forkJoin, map, of, take } from 'rxjs';
import { AddHostToGroupModalService } from '../../services/add-host-to-group-modal.service';
import { EditHostService } from '../../services/edit-host.service';
import { GitReposService } from '../../services/gitrepos.service';
import { HostsService } from '../../services/hosts.service';
import { RebootHostService } from '../../services/reboot-host.service';
import { RemoveHostModalService } from '../../services/remove-host-modal.service';
import { ActivateHostModalComponent, ActivateHostModalData } from '../activate-host-modal/activate-host-modal.component';
import { RemoveHostModalContentData } from '../remove-host-modal-content/remove-host-modal-content.model';

@Component({
  selector: 'app-hosts',
  templateUrl: './hosts.component.html',
  styleUrls: ['./hosts.component.scss']
})
export class HostsComponent extends AbstractPaginatableListComponent<HostResp> implements OnInit {
  readonly deleting$ = this.removeHostModalService.deleting$;
  readonly REGISTER_HOST_DOC_LINK = REGISTER_HOST_DOC_LINK;
  readonly EXPECTED_FILTER_CHANGE_ENTRIES: number = 2;
  readonly HostRegistrationStatus = HostRegistrationStatus;
  readonly HostRegistrationType = HostRegDetails.HostRegistrationTypeEnum;
  readonly HostOperationalStatus = HostOperationalStatus;

  private hostHealthArr: any = undefined;
  private operationalStatusArr: any = undefined;
  private sortOrder = ESortOrder.ASCENDING; // Default sortOrder
  private sortColumn = 'name'; // Default sortColumn
  private timeoutId: number | null = null;

  public filterValues: Map<string, string> = new Map<string, string>();
  public hosts: Array<any> = [];
  public selectedHostCount: number;
  public item: any;
  public pageSize: number;
  public currentPage: number;
  public totalHosts = 0;
  public loading = false;
  public getTimeStamp = getTimeStamp;
  public heroBanner = true;
  selected$ = this.hostService.selected$;
  public queryParamFilters = {};
  public appliedFilters = {};
  public readonly LEARN_MORE_ABOUT_HOST: string = DEPLOY_HOST_DOC_LINK;
  public readonly LEARN_MORE_ABOUT_HOSTS_ACTIVATION_STATUS: string = LEARN_MORE_ABOUT_HOSTS_ACTIVATION_STATUS;
  public partialLinkToMonitorHostDetailsView: string;

  readonly CONFIGURE_HOSTS_DETAILS_ROUTE = CONFIGURE_HOSTS_DETAILS_ROUTE;

  constructor(
    private hostService: HostsService,
    private modalService: VmwNgxModalService,
    private alertService: KeswickAlertService,
    private gitRepoService: GitReposService,
    private removeHostModalService: RemoveHostModalService,
    private route: ActivatedRoute,
    private router: Router,
    private editHostService: EditHostService,
    private addHostToGroupService: AddHostToGroupModalService,
    private cdr: ChangeDetectorRef,
    private l10service: L10nService,
    private rebootHostService: RebootHostService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
  ) {
    super(hostService);
    this.partialLinkToMonitorHostDetailsView = getAdjustedRoute(this.router, MONITOR_HOSTS_DETAILS_ROUTE);
    this.subs = this.pagination$.pipe(take(1)).subscribe(pagination => {
      this.pageSize = pagination.page.size;
      this.currentPage = pagination.page.current;
    });
    this.listenToValueChanges();
  }

  combinedLabels(host:HostResp){
    return host.desired_group_labels.concat(convertLabelsToUILabels(host.autoGeneratedLabels))
  }

  listenToValueChanges() {
    this.loading = true;

    this.subs = this.activatedRoute.queryParams.subscribe(selected => {
      this.queryParamFilters = selected;
      this.checkFiltersFromQueryParam();
    })

    this.subs = this.hostService.filterInputChanged.subscribe((vals: [string, string]) => {
      if (vals.length === this.EXPECTED_FILTER_CHANGE_ENTRIES) {
        this.onFilterChange(vals[0], vals[1]);
      }
    });

    this.subs = this.hostService.lastSeenStartDateChanged.subscribe(() => {
      this.onStartDateChange();
    });

    this.subs = this.hostService.lastSeenEndDateChanged.subscribe(() => {
      this.onEndDateChange();
    });

    // Get the filter criteria from the query parameter
    this.subs = this.route.queryParams.subscribe((params) => {
      const groupFilterValue = params?.['group_label'];
      const gitUrlFilterValue = params?.['git_repo_url'];
      if (groupFilterValue) {
        this.filterValues.set('group_label', groupFilterValue);
      }

      if (gitUrlFilterValue) {
        this.filterValues.set('url', gitUrlFilterValue);
      }

      const filterValue = params?.['desired_group_label'];
      if (filterValue) {
        this.filterValues.set('desired_group_label', filterValue);
      }
    });

  }

  checkFiltersFromQueryParam() { // Reading the filters from query params and setting that to variables, so that it will be used in API call.
    this.hostService.hostHealthStatus = [];
    this.hostService.hostOperationalStatus = [];
    this.hostService.filterValues.clear();
    this.hostService.hostHealthArr = undefined;
    this.hostService.operationalStatusArr = undefined;
    let removeDefaultFilters: Params = {};
    if (!_.isEmpty(this.appliedFilters) || !_.isEmpty(this.queryParamFilters)) {
      removeDefaultFilters = !_.isEmpty(this.appliedFilters) ? this.appliedFilters : this.queryParamFilters;
      const defaultFilters = Object.keys(this.hostService.defaultSelectValue);
      removeDefaultFilters = _.omit(removeDefaultFilters, defaultFilters);
      Object.keys(removeDefaultFilters).forEach((key: any) => {
        if (key === 'host_registration_status') {
          this.hostHealthArr = [removeDefaultFilters[key as keyof typeof removeDefaultFilters]];
          this.hostHealthArr = this.hostHealthArr[0].split(',')
          this.hostService.hostHealthArr = this.hostHealthArr;
        } else if (key === 'host_operational_status') {
          this.operationalStatusArr = [removeDefaultFilters[key as keyof typeof removeDefaultFilters]];
          this.operationalStatusArr = this.operationalStatusArr[0].split(',')
          this.hostService.operationalStatusArr = this.operationalStatusArr;
        } else {
          this.filterValues.set(key, removeDefaultFilters[key as keyof typeof removeDefaultFilters]);
          this.hostService.filterValues = this.filterValues;
        }
      });
      this.router.navigate([], { queryParams: !_.isEmpty(this.appliedFilters) ? this.appliedFilters : this.queryParamFilters })
    } else {
      this.router.navigate([], { queryParams: this.hostService.defaultSelectValue })
    }
    this.hostService.setQueryParams(removeDefaultFilters, this.hostHealthArr, this.operationalStatusArr);
  }

  addHost() {
    this.navigationService.sendNavigationRequest(CONFIGURE_ADD_HOST_ROUTE);
  }

  editHost() {
    if (this.selectedItems.length == 1) {
      const adjustedPath = getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE);
      this.router.navigate([adjustedPath, this.selectedItems[0].unique_identifier]);
    }
  }

  removeHost() {
    if (this.selectedItems.length > 0) {
      const hostsToRemove: RemoveHostModalContentData['hosts'] = this.selectedItems.map((host) => ({
        hostIdentifier: host.unique_identifier,
        hostSerialNumber: host.host_registration_details.host_serial_number,
        hostRegistrationStatus: host.host_registration_status,
        hostName: host.name
      }));
      this.subs = this.removeHostModalService.openRemoveHostModal(hostsToRemove).subscribe(() => {
        this.selectedItems = [];
      });
    }
  }

  rebootHost() {
    if (this.selectedItems.length === 1) {
      this.subs = this.rebootHostService.openRebootHostModal(this.selectedItems[0]).subscribe();
    }
  }

  async activateHostsHandler() {
    let requests: Observable<boolean>[] = []
    requests = this.selectedItems.map((host) => this.activateHost(host.unique_identifier))
    forkJoin(requests).subscribe((results) => {
      const totalSucceededRequests = results.filter((result) => result).length;
      if (totalSucceededRequests === requests.length) {
        this.alertService.showMessage({
          type: AlertType.success,
          message: this.l10service.getMessage("ecs.host.activation.success")
        });
      }
      else {
        this.alertService.showMessage({
          type: AlertType.failure,
          message: this.l10service.getMessage('ecs.activateHostModal.error', [
            totalSucceededRequests,
            requests.length - totalSucceededRequests
          ])
        });
      }
      this.hostService.getHosts(this.hostHealthArr);
    })
  }


  private activateHost(hostID: string): Observable<any> {
    return this.hostService.activateHost(hostID).pipe(
      map(() => { return true }),
      catchError(() => { return of(false) }))
  }

  openActivateHostModal() {
    Lumos.trackClickEvent('Active Hosts', 'Active Hosts page - ACTIVATE HOST clicked');
    this.hostService.modalRef = this.modalService.openOkCancel(ActivateHostModalComponent, {
      size: VmwNgxModalSize.Large,
      title: this.l10service.getMessage('ecs.activatehostmodal.title'),
      data: {
        selectedHosts: this.selectedItems.map(host => ({ name: host.name, id: host.unique_identifier, gitrepo: host.git_repo_url }))
      } as ActivateHostModalData,
      buttons: {
        submitButton: {
          label: this.l10service.getMessage('ecs.activateHostModal.button.activate'),
          disabled: this.selectedItems.filter(host => !host.git_repo_url).length > 0
        },
        cancelButton: {
          label: 'Cancel'
        }
      }
    });

    this.hostService.modalRef.onClose().subscribe((result: ActivateHostInfo | false) => {
      if (result) {
        this.activateHostsHandler();
      } else {
        Lumos.trackClickEvent('Not Active Hosts', 'Not Active Hosts - Close/Cancel Activate Host Modal');
      }
    });
  }

  onPageChange(page: number): void {
    if (page !== this.currentPage) {
      this.loading = true;
      this.currentPage = page;
      this.hostService.getHosts(this.hostHealthArr, this.operationalStatusArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
    }
  }

  onPageSizeChange(newPerPage: Event) {
    this.loading = true;
    this.pageSize = Number((newPerPage.target as HTMLInputElement).value.slice(3));
    this.currentPage = 1;
    this.hostService.getHosts(this.hostHealthArr, this.operationalStatusArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  sort(sortColumn: string, sortDirection: number): void {
    this.loading = true;
    this.sortColumn = sortColumn;
    this.sortOrder = sortDirection === 1 ? ESortOrder.ASCENDING : ESortOrder.DESCENDING;
    this.hostService.getHosts(this.hostHealthArr, this.operationalStatusArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  onStartDateChange() {
    this.loading = true;
    if (this.hostService.filterStartDate != undefined && this.hostService.filterStartDate != '') {
      const lastSeenStartRange = new Date(this.hostService.filterStartDate).toISOString();
      this.filterValues.set('last_seen_range_start', lastSeenStartRange);
    } else {
      this.filterValues.delete('last_seen_range_start');
    }
    this.setFilterValues(this.hostHealthArr, this.operationalStatusArr, this.filterValues);
    this.hostService.getHosts(this.hostHealthArr, this.operationalStatusArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  onEndDateChange() {
    this.loading = true;
    if (this.hostService.filterEndDate != undefined && this.hostService.filterEndDate != '') {
      const lastSeenEndRange = new Date(this.hostService.filterEndDate).toISOString();
      this.filterValues.set('last_seen_range_end', lastSeenEndRange);
    } else {
      this.filterValues.delete('last_seen_range_end');
    }
    this.setFilterValues(this.hostHealthArr, this.operationalStatusArr, this.filterValues);
    this.hostService.getHosts(this.hostHealthArr, this.operationalStatusArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  onFilterChange(filterColumn: string, filterValue: string | string[]) {
    this.loading = true;
    if (!filterValue) {
      // Empty filter value, delete the filterColumn from filterValues
      this.filterValues.delete(filterColumn);
    } else if (Array.isArray(filterValue) && filterColumn === 'host_health_status') {
      // Filters for host_health_status
      this.hostHealthArr = []
      if (filterValue.length > 0) {
        this.hostHealthArr.push(...filterValue);
      }
    } else if (Array.isArray(filterValue) && filterColumn === 'host_operational_status') {
      this.operationalStatusArr = []
      if (filterValue.length > 0) {
        this.operationalStatusArr.push(...filterValue);
      }
    } else {
      if (typeof filterValue === 'string') {
        this.filterValues.set(filterColumn, filterValue);
      }
    }

    // Clear previous timeout if exists
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.setFilterValues(this.hostHealthArr, this.operationalStatusArr, this.filterValues);
    this.hostService.getHosts(this.hostHealthArr, this.operationalStatusArr, this.currentPage, this.pageSize, this.sortColumn, this.sortOrder, this.filterValues);
  }

  setFilterValues(hostHealthArr: Array<HostRegistrationStatus>, operationalStatusArr: Array<HostOperationalStatus>, filterValues: Map<string, string>) { // Applying the filter in URL as query params
    let params;
    if (hostHealthArr && hostHealthArr.length > 0) {
      params = { ...HOST_DEFAULT_FILTERS, host_registration_status: hostHealthArr.join(',') }
    }  if (operationalStatusArr && operationalStatusArr.length > 0) {
      params = { ...HOST_DEFAULT_FILTERS, host_operational_status: operationalStatusArr.join(',') }
    } else {
      params = HOST_DEFAULT_FILTERS;
    }
    this.appliedFilters = { ...params, ...Object.fromEntries(filterValues) };
    this.router.navigate([], { queryParams: this.appliedFilters });
  }

  openAddToGroupModal() {
    Lumos.trackClickEvent('Active Hosts', 'ADD HOST TO GROUP clicked');
    this.addHostToGroupService.openAddHostToGroupModal(this.selectedItems as unknown as IHostData[]);
    this.selectedItems = [];
  }

  isAddToGroupDisabled(selectedItems: HostResp[]): boolean {
    if (selectedItems.length < 1) {
      return true;
    }
    const activeHosts = selectedItems.filter((host) => host.host_registration_status === HostRegistrationStatus.Active);
    return activeHosts.length != selectedItems.length;
  }

  isEditDisabled(): boolean {
    if (this.selectedItems?.length !== 1) {
      return true
    }
    return [HostRegistrationStatus.DeletionPending, HostRegistrationStatus.Activating].some((value) => value === this.selectedItems[0]?.host_registration_status);
  }

  isHostHealthActiveOrPending(selectedHost: HostResp) {
    return [HostRegistrationStatus.Active, HostRegistrationStatus.NotActive].some((value) => value === selectedHost?.host_registration_status);
  }

  canActivateHost(selectedItems: HostResp[]): boolean {
    if (!selectedItems || selectedItems?.length < 1) {
      return false;
    }

    return (selectedItems?.length === 1 && selectedItems[0].host_registration_status === HostRegistrationStatus.NotActive);
  }

  backClicked() {
    this.router.navigate(['/groups']);
  }

  
  openAddHostModal() {
    Lumos.trackClickEvent('Host', 'Add Host clicked');
    this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_ADD_HOST_ROUTE));
  }

  hideHeroBanner() {
    this.heroBanner = false;
  }

  openHostInstructions() {
    window.open(this.LEARN_MORE_ABOUT_HOST, '_blank');
  }

  onSelectionChange(selection: Array<any>) {
    this.hostService.setSelected(selection);
  }

  
}
