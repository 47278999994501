<clr-main-container class="main-container" [ngClass]="selectedThemeStyles">
  <div class="alert alert-app-level alert-danger" style="margin-bottom:24px" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
            </div>
            <div class="alert-text">
              Broadcom implemented the end of sale (EoS) for VMware Edge Compute Stack (ECS) offering on November 4, 2024. Any of your additional edge infrastructure deployment needs or new edge infrastructure use case needs may be met by purchasing the VMware Cloud Foundation Edge (VCF Edge) offering.
            </div>
        </div>
    </div>
  </div>
  <!-- <csp-header
    *ngIf="!cspServiceSdk.isEmbedded"
    [authToken]="authToken"
    [serviceRefLink]="serviceId"
    [options]="headerOptions"
    [openDrawer]="openDrawer"
    (switchOrg)="switchOrg($event)"
    (supportButtonClick)="openSupportForm()">
    <div class="csp-header-developer-panel">
      <div class="csp-header-docker-image-tag clr-row csp-dev-item">
        <label class="clr-col-5">{{ 'ecs.commit' | vtranslate }}</label>
        <div class="clr-col-7">{{ environment.commit || 'unknown' }}</div>
      </div>
    </div>
  </csp-header> -->
  <!-- <vmw-theme-switch-button classes="nav-icon nav-link" (click)="onThemeChange($event)" ></vmw-theme-switch-button> -->

  <app-sub-nav 
      [navItems]="horizontalTabs" 
      [selectedNavItemId]="selectedHorizontalTabId"
      (navItemSelected)="redirect($event)">
  </app-sub-nav>
  <!-- hiding theme switch button -->
  <!-- <vmw-theme-switch-button classes="nav-icon nav-link" (click)="onThemeChange()" ></vmw-theme-switch-button> -->
  <div class="content-container">
    <div ecs-sidenav></div>
    <div class="content-area" #contentArea>
      <app-keswick-alert></app-keswick-alert>
      <router-outlet></router-outlet>
    </div>
  </div>
</clr-main-container>
<!-- 
<clr-modal [(clrModalOpen)]="showSessionExpiredModal" [clrModalClosable]="false">
  <h3 class="modal-title">{{ 'ecs.sessionexpired.title' | vtranslate }}</h3>
  <div class="modal-body">
    <p>{{ 'ecs.sessionexpired.body' | vtranslate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="signOut()">{{ 'ecs.logout' | vtranslate }}</button>
    <button type="button" class="btn btn-outline" (click)="refreshAuthToken()">{{ 'ecs.refresh' | vtranslate }}</button>
  </div>
</clr-modal> -->
