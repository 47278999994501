import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscriber } from '@velocloud/angular-vc-common';
import { HostsService as HostApiService, CreateHost, GetGitReposResp, GetSitesResp, GitConfigSource, GitRepoResp, GitreposService, HostRegistrationStatus, SiteResp, SitesService, UpdateHost, HostRegDetails } from '@ecs/ecs-api';
import { ConfirmationService, GlobalConst, IComponentCanDeactivate, Lumos, PageHeaderBreadcrumb, findChangedValue } from '@ecs/ecs-common';
import { AlertType, CONFIGURE_HOSTS_ROUTE, getAdjustedRoute, IHostDetails, KeswickAlertService, OrgService } from '@ecs/ecs-platform';
import { VmwNgxModalSize } from '@vmw/ngx-modal-service';
import { L10nService } from '@vmw/ngx-vip';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, of, startWith, switchMap, forkJoin } from 'rxjs';
import { HostsService } from './../../services/hosts.service';
import { GitRepoConfigEditorComponent } from 'libs/ecs-common/src/lib/components/git-repo-config-editor/git-repo-config-editor.component';

export enum HostRegistrationType {
  HARDWARE_INFO = 'HardwareInfo',
  ACTIVATION_KEY = 'ActivationKey',
  BULK_UPLOAD = 'BulkUpload'
}

export enum SourceMode {
  CREATE = "create",
  EDIT = "edit"
}
@Component({
  selector: 'ecs-add-host',
  templateUrl: './add-host.component.html',
  styleUrls: ['./add-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddHostComponent extends Unsubscriber implements OnInit, IComponentCanDeactivate {
  @ViewChild(GitRepoConfigEditorComponent) gitRepoConfigEditorComponent: GitRepoConfigEditorComponent;
  private readonly loadingSource = new BehaviorSubject<boolean>(false);
  private readonly editHostInfo = new BehaviorSubject<IHostDetails>(null);

  readonly GitConfigSource = GitConfigSource;
  readonly loading$ = this.loadingSource.asObservable();

  breadcrumbs: PageHeaderBreadcrumb[] = [
    {
      label: this.l10nService.getMessage('ecs.hosts'),
      url: getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE)
    },
    {
      label: this.l10nService.getMessage('ecs.addhost.addhost')
    }
  ];
  addHostForm: FormGroup;
  isFormVaild = false
  selectedType = "serialNumber"
  currentStep = ''
  sites: SiteResp[] = []
  sitesLoaded = false;
  gitRepos: GitRepoResp[] = [];
  mode: SourceMode = SourceMode.CREATE
  editHostInfo$ = this.editHostInfo.asObservable();
  isAccessTokenPristine = true;
  hostName=""
  hostNameRegex:RegExp=null

  constructor(private formBuilder: FormBuilder,
    private alertService: KeswickAlertService,
    private hostService: HostsService,
    private l10nService: L10nService,
    private location: Location,
    private sitesService: SitesService,
    private orgService: OrgService,
    private gitRepoService: GitreposService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    super()
  }

  get isHostDetailsFormValid() {
    return this.addHostForm.get('hostDetailsStepperForm').valid
  }

  get isSiteDetailsFormValid() {
    return this.addHostForm.get('siteDetailsStepperForm').valid
  }

  get isgitRepoAssociationValid() {
    const gitRepo = this.addHostForm.get('gitRepoAssociation.gitDetails').value;
    return (gitRepo.git_repo_id || (this.addHostForm.get('gitRepoAssociation.git_config_source').value === GitConfigSource.Site))
  }

  get hostDetails() {
    return this.addHostForm.get('hostDetailsStepperForm').get('hostDetails').value
  }

  get siteDetails() {
    return this.addHostForm.get('siteDetailsStepperForm').get('siteDetails').value
  }

  get hostDetailsControl() {
    return this.addHostForm.get('hostDetailsStepperForm').get('hostDetails')
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return !this.addHostForm?.dirty;
  }

  ngOnInit() {
    this.subscribeToRouteParamsAndGetData();
    this.loadingSource.next(true);
    this.subs = this.orgService.orgId$.pipe(switchMap((orgId) => {
      return combineLatest([
        this.sitesService.listSites(orgId),
        this.gitRepoService.listGitRepos(orgId)
      ])
    })).subscribe(([siteResp, gitReposResp]: [GetSitesResp, GetGitReposResp]) => {
      this.sites = siteResp.results
      this.gitRepos = gitReposResp.results;

      this.subscribeToBuildForm();
      this.loadingSource.next(false);
    })
  }

  subscribeToRouteParamsAndGetData() {
    this.subs = this.route.params.subscribe((params: { id?: string }) => {
      this.mode = params.id ? SourceMode.EDIT : SourceMode.CREATE
      this.populateBreadCrumbs()
      if (this.mode === SourceMode.EDIT) {
        this.hostService.fetchHost(params.id).subscribe((hostInfo: IHostDetails) => {
          this.editHostInfo.next(hostInfo)
          this.moveToStep("gitRepoAssociation");
        })
      }
    });
  }

  private populateBreadCrumbs() {
    this.breadcrumbs = [
      {
        label: this.l10nService.getMessage('ecs.hosts'),
        url: getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE)
      },
      {
        label: this.mode === SourceMode.EDIT ? this.l10nService.getMessage('ecs.action.edithost') : this.l10nService.getMessage('ecs.addhost.addhost')
      }
    ];
  }

  submit() {
    if (this.hostDetails.hostDetailType === HostRegistrationType.ACTIVATION_KEY || this.hostDetails.hostDetailType === HostRegistrationType.HARDWARE_INFO) {
      const { hostDetailsStepperForm, siteDetailsStepperForm, gitRepoAssociation, tagsDetails } = this.addHostForm.getRawValue();
      const { vendor, model, serialNumber, name, activationKey } = hostDetailsStepperForm.hostDetails;
      const { siteId } = siteDetailsStepperForm.siteDetails;
      const { git_config_source, gitDetails: { git_repo_id, git_branch, git_path, git_username, git_access_token } } = gitRepoAssociation;
      const labels = tagsDetails.tags.filter((tag: any) => !!tag);

      this.editHostInfo$.pipe(switchMap(editHostData => {
        if (editHostData) {
          const updatedData: UpdateHost = {
            git_config_source: git_config_source as GitConfigSource,
            git_repo_id: git_repo_id || -1,
            git_repo_branch: git_branch || "",
            git_repo_path: git_path || "",
            git_repo_username: git_username || "",
            git_repo_access_token: git_access_token || "",
            labels,
            name
          }
          const originalData = this.getOriginalHostData(editHostData)
          const updateValuesInHost: UpdateHost = findChangedValue(originalData, updatedData)
          if (this.isAccessTokenPristine) {
            delete updateValuesInHost.git_repo_access_token
          }
          return this.hostService.updateHost(editHostData?.host_summary?.unique_identifier, updateValuesInHost)
        }
        else {
          const hostData: CreateHost = {
            host_vendor_identifier: vendor,
            host_model_identifier: model,
            host_serial_number: serialNumber,
            name: name,
            site_id: siteId,
            git_config_source: git_config_source as GitConfigSource,
            git_repo_id: gitRepoAssociation.gitDetails.git_repo_id,
            git_repo_branch: gitRepoAssociation.gitDetails.git_branch,
            git_repo_path: gitRepoAssociation.gitDetails.git_path,
            git_repo_username: gitRepoAssociation.gitDetails.git_username,
            git_repo_access_token: gitRepoAssociation.gitDetails.git_access_token,
            labels,
            host_registration_type: this.hostDetails.hostDetailType === HostRegistrationType.ACTIVATION_KEY ?
              CreateHost.HostRegistrationTypeEnum.ActivationKey
              : CreateHost.HostRegistrationTypeEnum.HardwareInfo,
            host_activation_key: activationKey
          }
          return this.hostService.addHostBySerialNumber(hostData)
        }
      })).subscribe({
        next: (_) => {
          this.addHostForm.markAsUntouched();
          this.addHostForm.markAsPristine();
          this.hostService.getHosts([HostRegistrationStatus.NotActive]);
          this.alertService.showMessage({
            type: AlertType.success,
            message: this.l10nService.getMessage(this.mode === SourceMode.CREATE ? 'ecs.addhostmodal.dormanthostadded' : 'ecs.addhost.edithost.success'),
            actionLabel: this.l10nService.getMessage('ecs.addhostmodal.dormanthostlist'),
            navigationLink: '#/' + CONFIGURE_HOSTS_ROUTE,
          });
          this.router.navigate([getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE)]);
        },
        error: (_) => {
          this.alertService.showMessage({
            type: AlertType.failure,
            message: this.l10nService.getMessage(this.mode === SourceMode.CREATE ? 'ecs.addhost.addhost.failure' : 'ecs.addhost.edithost.failure')
          });
        }
      });
    }
    else if (this.hostDetails.hostDetailType === HostRegistrationType.BULK_UPLOAD) {
      const file = this.hostDetails.csvFile
      const { siteId } = this.siteDetails
      this.subs = this.hostService.addHostByCSVFile(file, siteId).subscribe((res) => {
        this.addHostForm.markAsUntouched();
        this.addHostForm.markAsPristine();
        const registeredHosts = res?.registered_hosts?.filter((host) => host?.registration_error?.message == 'None');
        if (registeredHosts?.length !== 0) {
          // Some/All hosts were successfully registered, display the message returned
          // by backend
          this.alertService.showMessage({
            type: AlertType.success,
            message: res?.status?.status
          });
        } else {
          // Failed to registered all hosts present in the csv file
          this.alertService.showMessage({
            type: AlertType.failure,
            message: res?.status?.status
          });
        }

        this.hostService.getHosts([HostRegistrationStatus.NotActive]);
        this.location.back()
      });
    }
  }



  cancel(): void {
    if (this.addHostForm.dirty) {
      this.confirmationService.confirm({
        title: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.title'),
        message: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.body'),
        confirmButtonText: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.accept'),
        cancelButtonText: this.l10nService.getMessage('common.componentCanDeactivateGuard.modal.reject'),
        size: VmwNgxModalSize.Medium,
        confirm: () => {
          return;
        },
        cancel: () => {
          this.addHostForm.markAsUntouched();
          this.addHostForm.markAsPristine();
          this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE));
        }
      })
    } else {
      this.router.navigateByUrl(getAdjustedRoute(this.router, CONFIGURE_HOSTS_ROUTE));
    }
  }

  navigateToSiteDetails() {
    let duplicateHostObservable: Observable<boolean>[] = [];
    if(this.hostName!=this.hostDetails.name && this.hostDetails.hostDetailType!= HostRegistrationType.BULK_UPLOAD){
      duplicateHostObservable.push(this.hostService.checkDuplicateHost(undefined,
        undefined, undefined,
        undefined, undefined, this.hostDetails.name))
    }

    if (this.mode === SourceMode.CREATE && this.hostDetails.hostDetailType === HostRegistrationType.HARDWARE_INFO) {
      duplicateHostObservable.push(this.hostService.checkDuplicateHost(this.hostDetails.model,
        this.hostDetails.vendor, this.hostDetails.serialNumber,
        undefined, this.hostDetails.hostDetailType, undefined))
    }
    else if (this.mode === SourceMode.CREATE && this.hostDetails.hostDetailType === HostRegistrationType.ACTIVATION_KEY) {
      duplicateHostObservable.push(this.hostService.checkDuplicateHost(undefined,
        undefined, undefined,
        this.hostDetails.activationKey , this.hostDetails.hostDetailType, undefined))
    }

    if(duplicateHostObservable.length===0){
      this.moveToStep("siteDetailsStepperForm");
      this.cdr.detectChanges()
      return;
    }

    this.subs = forkJoin(duplicateHostObservable).subscribe(([isNameDuplicate, isRegistrationDetailsDuplicate]) => {
      if (!(isNameDuplicate || isRegistrationDetailsDuplicate)) {
        this.moveToStep("siteDetailsStepperForm");
        this.cdr.detectChanges()
      }
      else {
        this.hostDetailsControl.setValue({ ...this.hostDetailsControl.value, isHostDetailsValid: false })
        if (isRegistrationDetailsDuplicate) {
          this.alertService.showMessage({
            type: AlertType.failure,
            message: this.hostDetails.hostDetailType === HostRegistrationType.HARDWARE_INFO ? this.l10nService.getMessage('ecs.addhost.hardwareinfo.duplicatehostdetails') : this.l10nService.getMessage('ecs.addhost.activationnkey.duplicatehostdetails')
          });
        }
        if (isNameDuplicate) {
          this.alertService.showMessage({
            type: AlertType.failure,
            message: this.l10nService.getMessage('ecs.addhost.duplicatename')
          });
        }
      }
    })
  }

  updateTokenSuccess(): void {
    this.gitRepoConfigEditorComponent.updateTokenSuccess();
    this.isAccessTokenPristine = this.gitRepoConfigEditorComponent.form.get('git_access_token').pristine;
  }

  // Custom Validator Function
  private hostDetailsFormValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      this.hostNameRegex= new RegExp(GlobalConst.patterns.nameRegex);
      const inputValue: any = control.value;
      if (inputValue && inputValue.hostDetailType === HostRegistrationType.HARDWARE_INFO && inputValue.vendor && inputValue.model && inputValue.serialNumber && inputValue.name && inputValue.isHostDetailsValid && this.hostNameRegex.test(inputValue.name)) {
        return null;
      }
      else if (inputValue && inputValue.hostDetailType === HostRegistrationType.BULK_UPLOAD && inputValue.csvFile) {
        return null
      }
      else if (inputValue && inputValue.hostDetailType === HostRegistrationType.ACTIVATION_KEY && inputValue.activationKey && inputValue.name && inputValue.isHostDetailsValid && this.hostNameRegex.test(inputValue.name)) {
        return null
      }
      else {
        return { error: true }
      }
    }
  }

  // Custom Validator Function
  private gitDetailsFormValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputValue: any = control.value;
      if (inputValue && inputValue.git_repo_id !== null)
        return null;
      else {
        return { error: true }
      }
    }
  }

  private siteDetailsFormValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputValue: any = control.value;
      if (inputValue && inputValue.siteId) {
        return null;
      }
      else {
        return { error: true }
      }
    }
  }


  private subscribeToBuildForm() {
    this.editHostInfo$.subscribe((editHostData: IHostDetails) => {
      this.hostName=editHostData?.host_summary?.name

      let selectedSite = this.sites.filter(s => s.is_default).shift();
      let gitConfigSource = selectedSite?.git_repo_id ? GitConfigSource.Site : GitConfigSource.Host;
      let gitRepoId: number;

      if (this.mode === SourceMode.EDIT && editHostData != null) {
        selectedSite = this.sites.filter(s => s.id === editHostData?.host_summary?.site_id).shift()
        gitConfigSource = editHostData?.host_summary?.git_config_source as GitConfigSource
        gitRepoId = this.gitRepos.filter(g => g.url === editHostData?.host_summary?.git_repo_url).shift()?.id
        this.buildForm(editHostData, selectedSite, gitConfigSource, gitRepoId);
        this.subscribeToFormChanges();
      }
      else if (this.mode === SourceMode.CREATE) {
        this.buildForm(editHostData, selectedSite, gitConfigSource, gitRepoId);
        this.subscribeToFormChanges();
      }
    })

  }

  private buildForm(editHostData: IHostDetails, selectedSite: SiteResp, gitConfigSource: string, gitRepoId: number) {
    this.addHostForm = this.formBuilder.group({
      hostDetailsStepperForm: this.formBuilder.group({
        hostDetails: [{
          hostDetailType: editHostData ?
            (editHostData.host_summary.host_registration_details.host_registration_type.toString() === HostRegistrationType.HARDWARE_INFO.toString()
              ? HostRegistrationType.HARDWARE_INFO
              : HostRegistrationType.ACTIVATION_KEY)
            : HostRegistrationType.HARDWARE_INFO,
          vendor: editHostData ? editHostData.host_summary.host_registration_details.host_vendor_identifier : '',
          model: editHostData ? editHostData.host_summary.host_registration_details.host_model_identifier : '',
          serialNumber: editHostData ? editHostData.host_summary.host_registration_details.host_serial_number : '',
          name: editHostData ? editHostData.host_summary.name : '',
          csvFile: '',
          fileName: '',
          isHostDetailsValid: true,
          activationKey: editHostData ? editHostData.host_summary.host_registration_details.host_activation_key : ''
        }, this.hostDetailsFormValidator()]
      }),
      siteDetailsStepperForm: this.formBuilder.group({
        siteDetails: [{
          siteId: selectedSite?.id,
          site: selectedSite?.name,
        }, this.siteDetailsFormValidator()]
      }),
      gitRepoAssociation: this.formBuilder.group({
        git_config_source: gitConfigSource,
        gitDetails: [{
          git_repo_id: gitConfigSource === GitConfigSource.Site ? selectedSite?.git_repo_id : editHostData ? gitRepoId : null,
          git_branch: gitConfigSource === GitConfigSource.Site ? selectedSite?.git_repo_branch : editHostData?.host_summary?.git_repo_branch || "",
          git_path: gitConfigSource === GitConfigSource.Site ? selectedSite?.git_repo_path : editHostData?.host_summary?.git_repo_path || "",
          git_username: gitConfigSource === GitConfigSource.Site ? selectedSite?.git_repo_username : editHostData?.host_summary?.git_repo_username || "",
          git_access_token: '',
        }, this.gitDetailsFormValidator()]
      }),
      tagsDetails: this.formBuilder.group({
        tags: this.formBuilder.control(this.mode === SourceMode.EDIT ? this.convertArrayToObjectArray(editHostData?.host_summary?.desired_group_labels) : [])
      })
    });
    this.cdr.detectChanges();
  }

  private subscribeToFormChanges(): void {
    let gitRepoId: number;

    const gitConfigSource = this.addHostForm.get('gitRepoAssociation.git_config_source').value;
    const initialSiteDetails = this.addHostForm.get('siteDetailsStepperForm.siteDetails').value;
    this.subs = combineLatest([
      this.addHostForm.get('gitRepoAssociation.git_config_source').valueChanges.pipe(startWith(gitConfigSource), distinctUntilChanged()),
      this.addHostForm.get('siteDetailsStepperForm.siteDetails').valueChanges.pipe(startWith(initialSiteDetails), distinctUntilChanged()),
      this.editHostInfo$
    ]).subscribe(([value, selectedSite, editHostData]: [string, { siteId: string }, IHostDetails]) => {
      const gitDetails = this.addHostForm.get('gitRepoAssociation.gitDetails');
      const site = this.sites.find(s => s.id === selectedSite.siteId);
      gitRepoId = this.gitRepos.filter(g => g.url === editHostData?.host_summary?.git_repo_url).shift()?.id

      if (value === GitConfigSource.Site) {
        // Retrieve Site-level git details and update the gitDetails formControl
        gitDetails.disable();
        gitDetails.reset({
          git_repo_id: site?.git_repo_id,
          git_branch: site?.git_repo_branch,
          git_path: site?.git_repo_path,
          git_username: site?.git_repo_username,
          git_access_token: '',
        });
        gitDetails.markAsPristine();
        gitDetails.markAsUntouched();
      } else if (value === GitConfigSource.Host) {
        gitDetails.enable();
        gitDetails.reset({
          git_repo_id: editHostData ? gitRepoId : null,
          git_branch: editHostData?.host_summary?.git_repo_branch || "",
          git_path: editHostData?.host_summary?.git_repo_path || "",
          git_username: editHostData?.host_summary?.git_repo_username || "",
          git_access_token: "",
        });
      }
    });

    this.subs = this.addHostForm.get('hostDetailsStepperForm').get('hostDetails').valueChanges
      .pipe(distinctUntilChanged((prev, curr) => prev.vendor === curr.vendor && prev.model === curr.model && prev.serialNumber === curr.serialNumber && prev.name === curr.name))
      .subscribe(() => {
        this.hostDetailsControl.setValue({ ...this.hostDetailsControl.value, isHostDetailsValid: true });
      });

    this.subs = this.addHostForm.get('gitRepoAssociation.gitDetails').valueChanges.pipe(distinctUntilChanged((prev, current) => {
      return prev.git_repo_id === current.git_repo_id;
    })).subscribe(({ git_repo_id }) => {
      const gitConfigSource = this.addHostForm.get('gitRepoAssociation.git_config_source').value;
      if (gitConfigSource === GitConfigSource.Host) {
        const selectedGitRepo = this.gitRepos.find((gitRepo) => gitRepo.id === Number(git_repo_id));
        if (gitRepoId !== selectedGitRepo?.id) {
          this.addHostForm.get('gitRepoAssociation.gitDetails').patchValue({
            git_repo_id: selectedGitRepo.id,
            git_branch: selectedGitRepo.default_branch,
            git_path: '/',
            git_username: '',
            git_access_token: '',
          });
        }
      }
    });
  }

  private moveToStep(step: string) {
    this.currentStep = '';
    setTimeout(() => {
      this.currentStep = step;
      this.cdr.detectChanges()
    }, 0);
  }

  private getOriginalHostData(editHostData: IHostDetails) {
    return {
      git_config_source: editHostData?.host_summary?.git_config_source,
      git_repo_id: this.gitRepos.filter(gitRepo => gitRepo.url === editHostData.host_summary.git_repo_url)?.shift()?.id || -1,
      git_repo_branch: editHostData?.host_summary?.git_repo_branch,
      git_repo_path: editHostData?.host_summary?.git_repo_path,
      git_repo_username: editHostData?.host_summary?.git_repo_username,
      labels: this.convertArrayToObjectArray(editHostData?.host_summary?.desired_group_labels),
      name: editHostData?.host_summary.name
    };
  }

  private convertArrayToObjectArray(arr: string[]): { label_key: string, label_value: string }[] {
    return (arr || []).map((item) => {
      const parts = item.split(':');
      const label_key = parts[0];
      const label_value = parts.length > 1 ? parts[1] : '';
      return { label_key, label_value };
    });
  }

}
