import { Component, OnInit } from "@angular/core";
import { ClrDatagridStateInterface } from "@clr/angular";
import { debounceTime, distinctUntilChanged, switchMap, take } from "rxjs";
import { AbstractPaginatableListService } from "./abstract-paginatable-list.service";
import { Unsubscriber } from "@velocloud/angular-vc-common";

@Component({
    template: ''
})
export abstract class AbstractPaginatableListComponent<T> extends Unsubscriber implements OnInit {
    readonly loading$ = this.listService.loading$;
    readonly data$ = this.listService.data$;
    readonly totalItems$ = this.listService.totalItems$;
    readonly pagination$ = this.listService.pagination$;

    selectedItems: T[] = [];

    constructor(private listService: AbstractPaginatableListService<T>) {
        super();
    }

    ngOnInit(): void {
        this.subs = this.listService.pagination$.pipe(distinctUntilChanged((prev, next) => {
            return prev.page?.current === next.page?.current &&
                prev.page?.size === next.page?.size &&
                prev.sort?.by === next.sort?.by &&
                prev.sort?.reverse === next.sort?.reverse &&
                prev.filters?.length === next.filters?.length &&
                prev.filters?.every((filter, index) => {
                    return filter.property === next.filters[index]?.property && filter.value === next.filters[index]?.value;
                });
        }),
        debounceTime(300), switchMap((pagination) => {
            return this.listService.fetchData({
                ...pagination,
                filters: pagination?.filters ?? [],
            });
        })).subscribe();
    }

    onDatagridRefresh(data: ClrDatagridStateInterface<any>): void {
        this.subs = this.pagination$.pipe(take(1)).subscribe(pagination => {
            const { current, size } = data?.page ?? pagination.page;
            const { by, reverse } = data?.sort ?? pagination.sort ?? { by: '', reverse: false };

            const updatedPagination = {
                ...pagination,
                page: {
                    ...pagination.page,
                    current,
                    size
                },
                sort: {
                    by,
                    reverse
                },
                filters: data?.filters ?? pagination.filters ?? [] ,
            };
            this.listService.pagination = updatedPagination;
        });
    }

    onRefresh(): void {
        this.selectedItems=[]
        this.subs = this.listService.pagination$.pipe(take(1), debounceTime(300), switchMap(pagination => {
            return this.listService.fetchData(pagination);
        })).subscribe();
    }
}