import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { OrgService } from '@ecs/ecs-platform';
import { handleError } from '@ecs/ecs-common';
import { AlertResp } from '@ecs/ecs-api';

@Injectable({
  providedIn: 'root'
})
export class OperationsService {
  private orgID: string;
  constructor(private http: HttpClient, private orgService: OrgService) {
    this.orgService.orgId$.subscribe((orgID) => {
      this.orgID = orgID;
    });
  }

  dismissAlerts(alertIds: Array<AlertResp>): Observable<any> {
    return this.http.post(this.getUrl('alerts/suppress'), { alert_ids: alertIds }).pipe(catchError(handleError));
  }

  private getUrl(subPath: string): string {
    const prefixUrl = this.orgService.getVcoEnterpriseOrgBaseUrl();
    return `${prefixUrl}/${subPath}`;
  }
}
