import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GitrepoComponent } from './gitrepo/gitrepo.component';
import { GitreposListComponent } from './gitrepos-list/gitrepos-list.component';
import { AddGitrepoModalComponent } from './add-gitrepo-modal/add-gitrepo-modal.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { EcsI8nModule } from '../ecs-i18n/ecs-i18n.module';
import { EcsCommonModule } from '@ecs/ecs-common';

@NgModule({
  declarations: [GitrepoComponent, GitreposListComponent, AddGitrepoModalComponent],
  exports: [GitrepoComponent, GitreposListComponent, AddGitrepoModalComponent],
  imports: [CommonModule, ClarityModule, FormsModule, ReactiveFormsModule, HttpClientModule, RouterModule, EcsI8nModule, EcsCommonModule]
})
export class GitRepoModule {}
