export const ENG_ECS_ALERTS_TRANSLATIONS = {
  'ecs.operations.alerts.title': 'Alerts',
  'ecs.operations.alerts.columns.alert': 'Alert',
  'ecs.operations.alerts.columns.alert.placeholder': 'Filter alert',
  'ecs.operations.alerts.columns.severity': 'Severity',
  'ecs.operations.alerts.columns.severity.warning': 'Warning',
  'ecs.operations.alerts.columns.severity.critical': 'Critical',
  'ecs.operations.alerts.columns.source.placeholder': 'Alert source',
  'ecs.operations.alerts.columns.grouplabel.placeholder': 'Filter Host Applied Labels',
  'ecs.operations.noalerts': 'No alerts found',
  'ecs.operations.alerts.dismissalertconfirmation': 'Are you sure you want to dismiss the selected alerts?',
  'ecs.operations.alerts.footer': 'Alerts per page',
  'ecs.operations.alerts.pagination': '{0} - {1} of {2} alerts',
  'ecs.dismissalert.success': 'Successfully dismissed selected alerts!'
};
