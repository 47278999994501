export const MODAL_ACCEPT = 'accept';
export const MODAL_DENY = 'deny';

// External Doc Links
export const KESWICK_DEMO_VIDEO_URL = 'https://www.youtube.com/watch?v=wpdaWlyNp7k';
export const BASE_URL = 'https://techdocs.broadcom.com/us/en/vmware-sde/edge-compute/vmware-edge-compute-stack/3-5/vmware-edge-compute-stack-administration-guide';
export const LEARN_MORE_ABOUT_KESWICK = `${BASE_URL}/getting-started-with-project-keswick.html`;
export const LEARN_MORE_ABOUT_GIT_REPO = `${BASE_URL}/setting-up-a-git-repository.html`;
export const LEARN_MORE_ABOUT_SITES = `${BASE_URL}/sites.html`;
export const DOWNLOAD_IMAGE_DOC_LINK = `${BASE_URL}/downloading-the-keswick-media.html`;
export const HOST_PRE_REQ_DOC_LINK = `${BASE_URL}/system-requirements.html`;
export const DEPLOY_HOST_DOC_LINK = `${BASE_URL}/deploying-edge-hosts.html`;
export const FULL_DEPLOYMENT_VIDEO_LINK = 'https://www.youtube.com/watch?v=wpdaWlyNp7k';
export const INTRODUCE_KESWICK_ARTICLE_LINK =
  'https://octo.vmware.com/edge-management-at-scale-announcing-free-availability-of-vmware-project-keswick/';
export const SIMPLIFYING_EDGE_DEPLOY_ARTICLE_LINK = 'https://octo.vmware.com/project-keswick/';
export const DEPLOYING_HOME_LAB_WITH_KESWICK_LINK = 'https://www.youtube.com/watch?v=FtjfsE9QVHU';
export const HOME_DOC_LINK = `${BASE_URL}/copy-of-introduction.html`;
export const TROUBLESHOOTING_HOST = `${BASE_URL}/troubleshooting.html`;
export const HOST_GROUP_DOC_LINK = `${BASE_URL}/working-with-host-groups-1.html`;
export const ADD_HOST_TO_GROUP_DOC_LINK = `${BASE_URL}/working-with-host-groups-1/adding-a-host-to-an-existing-group.html`;
export const ADD_SITE_DOC_LINK = `${BASE_URL}/sites/creating-a-site.html`;
export const REGISTER_HOST_DOC_LINK = `${BASE_URL}/registering-a-keswick-host.html`;
export const REGISTER_GIT_REPO_DOC_LINK = `${BASE_URL}/registering-a-git-repository.html`;
export const ADD_GROUP_DOC_LINK = `${BASE_URL}/working-with-host-groups-1/working-with-host-groups.html`;
export const LEARN_MORE_ABOUT_HOSTS_ACTIVATION_STATUS = `${BASE_URL}/host-activation-status.html`;

// Global Patterns
export const GlobalConst = {
    patterns: {
      nameRegex: '^[a-zA-Z0-9](?:[a-zA-Z0-9_. ]*[a-zA-Z0-9])?$', // Compliant with K8s label value
      labelKey: /^(?:(?:[a-zA-Z0-9]([-a-zA-Z0-9]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}\/)?[a-zA-Z0-9](?:[a-zA-Z0-9-_.]*[a-zA-Z0-9])?$/g, // Optional Prefix followed by key. For example: example.com/my-label
      labelValue: '^[a-zA-Z0-9](?:[a-zA-Z0-9-_.]*[a-zA-Z0-9])?$'
    }
}