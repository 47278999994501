import '@cds/core/icon/register.js';
import {
  ClarityIcons,
  dashboardIcon,
  homeIcon,
  nodeIcon,
  nodesIcon,
  vmBugIcon,
  hostIcon,
  ciCdIcon,
  launchpadIcon,
  carIcon,
  angleIcon,
  warningStandardIcon,
  infoStandardIcon,
  refreshIcon,
  fileIcon,
  downloadIcon,
  windowCloseIcon,
  deployIcon,
  buildingIcon,
  pencilIcon,
  plusIcon,
  minusCircleIcon,
  plusCircleIcon,
  syncIcon,
  processOnVmIcon,
  organizationIcon,
  blocksGroupIcon,
  trashIcon,
  twoWayArrowsIcon,
  bubbleExclamationIcon,
  clusterIcon
} from '@cds/core/icon';
import { launchpadIconSvg } from './launchpad.icon';

export function initClarityIconRegistry() {
  return () => {
    ClarityIcons.addIcons(
      homeIcon,
      nodesIcon,
      vmBugIcon,
      dashboardIcon,
      nodeIcon,
      hostIcon,
      ciCdIcon,
      launchpadIcon,
      angleIcon,
      warningStandardIcon,
      infoStandardIcon,
      refreshIcon,
      fileIcon,
      downloadIcon,
      windowCloseIcon,
      deployIcon,
      buildingIcon,
      pencilIcon,
      plusIcon,
      minusCircleIcon,
      plusCircleIcon,
      syncIcon,
      processOnVmIcon,
      organizationIcon,
      blocksGroupIcon,
      trashIcon,
      twoWayArrowsIcon,
      bubbleExclamationIcon,
      clusterIcon
    );

    ClarityIcons.addIcons(['edgecompute', launchpadIconSvg]);
  };
}
