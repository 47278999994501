<h2>{{ 'ecs.gitrepositories' | vtranslate }}</h2>
  <ecs-page-info [bannerDescription]="'ecs.gitrepo.banner' | vtranslate">

<div class="hero-section" *ngIf="heroBanner">
  <div class="hero-content">
    <div>
      <div class="hero-top">
        <h6>{{ 'ecs.gitrepo.gettingstarted' | vtranslate }}</h6>
      </div>
      <div class="info-description">
      <div>
        {{ 'ecs.gitrepo.banner' | vtranslate }}
      </div>
      <div>
        {{ 'ecs.gitrepo.description' | vtranslate }}
        <span
          appI18nLinkTarget="link"
          (i18nLinkTargetClicked)="openGitInstructions()"
          [innerHTML]="'ecs.gitrepo.subdescription' | vtranslate">
        </span>
      </div>
      </div>
    </div>
    <img src="/apps/ecs/assets/git-repos-hero-image-light.svg" alt="git repos hero section"/>
  </div>
  <div class="info-buttons">
  <button class="btn btn-primary" (click)="openAddGitRepoModal()">{{ 'ecs.gitrepo.addgitrepository' | vtranslate }}</button>
  <a [href]="LEARN_MORE_ABOUT_GIT_REPO" target="_blank" rel="noopener noreferrer">
    <button class="btn btn-outline">{{ 'ecs.gitrepo.learnmore' | vtranslate }}</button>
  </a>
</div>
</div>

</ecs-page-info>
<app-gitrepos-list></app-gitrepos-list>
