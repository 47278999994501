export const ENG_ECS_LAUNCHPAD_TRANSLATIONS = {
  'ecs.launchpad.edgecomputeservice.title': 'VMware Edge Compute Stack',
  'ecs.launchpad.edgecomputeservice.subtitle':
    'VMware Edge Compute Stack simplifies the deployment, management, and support of workloads at the edge. Built on top of the ESXi hypervisor, each ' +
    'deployment is entirely automated through desired state configuration. Powered by GitOps, your Git repository serves as the single source of ' +
    'truth for your edge infrastructure.',
  'ecs.launchpad.edgecomputeservice.description':
    "To get started, simply deploy your hosts with an Edge Compute image and add a Git repository with your applications defined " +
    'as Kubernetes manifests.',
  'ecs.launchpad.edgecomputeservice.learnmore': 'Learn More',
  'ecs.launchpad.edgecomputeservice.watchademo': 'Watch a Demo',
  'ecs.launchpad.edgecomputeservice.gettingstarted': 'Getting started with Edge Compute Stack'
};
