import {  Component, OnInit } from '@angular/core'; 
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { MonitorHostsService } from '../monitor-hosts/monitor-hosts.service';
import { L10nService } from '@vmw/ngx-vip';
import { Unsubscriber } from '@velocloud/angular-vc-common';

@Component({
  selector: 'ecs-hosts-registraion-and-health-charts',
  templateUrl: './host-registration-and-health-charts.component.html',
  styleUrls: ['./host-registration-and-health-charts.component.scss']
})
export class HostRegistraionAndHealthChartsComponent  extends Unsubscriber implements OnInit  { 
    public totalHost: string;
    public totalHealtyUnHealthy: string;
    public totalActiveHost: number;
    public totalActivated = {};
    public chartDataByRegistrationStatus:any;
    public chartDataByHealthStatus:any;
     
    constructor(
        private hostService: MonitorHostsService,    
        private l10service: L10nService,
    ) { 
        super();
    }
    ngOnInit(): void {
        this.subs = forkJoin([this.hostService.getHostsCount('host_registration_status'), this.hostService.getHostsCount('host_health_status')]) //we can use more that 2 api request 
        .subscribe(
            result => {
                 
                const [res1,res2] = result;
                const registered = res1["Registered"]??0;
                const notActive = res1["NotActive"]??0;
                const activating = res1["Activating"]??0;
                const deletionPending = res1["DeletionPending"]??0;
                const active = res1["Active"]??0;

                this.totalHost = (registered+notActive+activating+deletionPending+active).toString();
                this.totalHealtyUnHealthy = (res2["UnHealthy"] + res2["Healthy"]).toString();
                this.totalActivated = active + deletionPending;

                this.chartDataByRegistrationStatus = [{
                    id: 'registered',
                    name: this.l10service.getMessage('ecs.host.registration.status.Registered'),
                    value: registered,
                    color: '#4aaed9'
                },
                {
                    id: 'notActive',
                    name: this.l10service.getMessage('ecs.host.registration.status.notActive'),
                    value: notActive,
                    color: '#fdd008'
                },
                {
                    id: 'activating',
                    name: this.l10service.getMessage('ecs.host.registration.status.activating'),
                    value: activating,
                    color: '#61b715'
                },
                {
                    id: 'active',
                    name: this.l10service.getMessage('ecs.host.registration.status.activated'),
                    value: active,
                    color: '#61b715'
                },
                {
                    id: 'deletionPending',
                    name: this.l10service.getMessage('ecs.host.registration.status.deletionPending'),
                    value: deletionPending,
                    color: '#fdd008'
                }
                ]; 
            
        
            this.chartDataByHealthStatus = [{
                id: 'red',
                name: this.l10service.getMessage('ecs.host.health.status.unhealthy'),
                value: res2["UnHealthy"]??0,
                color: '#ff0000'
                }, {
                id: 'green',
                name: this.l10service.getMessage('ecs.host.health.status.healthy'),
                value: res2["Healthy"]??0,
                color: '#63a420'
            }]; 
                
                 
            }
        )

        
         
    }
}